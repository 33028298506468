/**
 * @file contains helper function for preparing attachments for upload
 */

import { Dispatch } from 'redux';

import {
  AttachmentDraft,
  AttachmentDraftPreUploadMapEntry,
} from '../../models/attachment';
import { DraftActionAttachment } from '../../models/draftAttachment';
import uploadFile from '../../services/uploadFile';
import { addDraftAttachmentRequest } from '../../store/actions/draftsAttachments';
import { reportApiErrors } from '../error';

/**
 * Prepares a list of attachments for upload and dispatches actions to update the draft state.
 *
 * @param attachments A map of attachment drafts to be uploaded.
 * @param dispatch    The dispatch function to send actions to the store.
 * @param parentId    The ID of the parent entity to which the attachments belong.
 * @returns           An array of promises, each representing the upload process of an attachment.
 */
const getUploadPromises = (
  attachments: Map<string, AttachmentDraftPreUploadMapEntry>,
  dispatch: Dispatch<DraftActionAttachment>,
  parentId: string,
): Promise<void | Response | undefined>[] => {
  const uploadPromises: Promise<void | Response | undefined>[] = [];

  /**
   * Updates the draft state with the attachment draft and additional properties
   *
   * @param draft The attachment draft to update
   * @param props Additional properties to update
   */
  const addDraft = (
    draft: AttachmentDraft,
    props?: Partial<AttachmentDraft>,
  ) => {
    dispatch(
      addDraftAttachmentRequest(parentId, {
        ...draft,
        ...props,
      }),
    );
  };

  attachments.forEach(attachment => {
    const draft: AttachmentDraft = {
      ...attachment,
      key: attachment.postData.fields.key,
    };

    addDraft(draft);

    const promise = uploadFile(attachment)
      .then(() => {
        addDraft(draft, { isUploading: false });
      })
      .catch(reportApiErrors);

    // Add file upload to array of promises
    uploadPromises.push(promise);
  });

  return uploadPromises;
};

export default getUploadPromises;
