/**
 * @file Hook for handling attachment preview
 */
import { useCallback } from 'react';

import postAttachmentActionEvent from '../../native-messaging/handlers/web/attachmentAction';
import { reportApiErrors } from '../../utils/error';
import {
  getIsPwaIos,
  getIsReactNativeWebView,
} from '../../utils/webview/helpers';

/**
 * Handle file preview based on the environment:
 *
 * - React Native WebView: Post attachment action event
 * - PWA iOS: Fetch file locally to circumvent iOS Safari cross domain error
 * - Other: Set link to the received link
 *
 * @param setLink Function to set link (used for PWA iOS and local file blob)
 * @returns       Function to handle file preview
 */
const useAttachmentPreview = (setLink: (link: string) => void) => {
  const isReactNativeWebView = getIsReactNativeWebView();
  const isPwaIos = getIsPwaIos();

  /**
   * Handle file preview based on the environment:
   *
   * - React Native WebView: Post attachment action event
   * - PWA iOS: Fetch file locally to circumvent iOS Safari cross domain error
   * - Other: Set link to the received link
   *
   * @param filename Name of the file
   * @param link     Link to the file
   */
  const handleFilePreview = useCallback(
    (filename: string, link: string) => {
      if (isReactNativeWebView) {
        postAttachmentActionEvent('preview', link);
        setLink('');
        return;
      }

      if (isPwaIos === false) {
        setLink(link);
        return;
      }

      fetch(link)
        .then(response => response.blob())
        .then(blob => {
          const file = new File([blob], filename, { type: blob.type });
          return URL.createObjectURL(file);
        })
        .then(setLink)
        .catch(reportApiErrors);
    },
    [isReactNativeWebView, isPwaIos, setLink],
  );

  return handleFilePreview;
};

export default useAttachmentPreview;
