/**
 * @file contains utils related to environment variables
 */

import { ENV_PRODUCTION } from '../constants/environments';

export const isProduction =
  typeof ENVIRONMENT !== 'undefined' && ENVIRONMENT === ENV_PRODUCTION;
export const isTest = typeof jest !== 'undefined';

/**
 * Get whether to use URQL dev tools exchange
 *
 * @returns Whether to use it or not
 */
export const getShouldUseUrqlDevTools = (): boolean => {
  return isProduction === false && isTest === false;
};
