import { Button, ButtonProps, styled } from '@mui/material';

import { LinkProps } from 'react-router-dom';

import devices from '../../../styles/devices';

export const Footer = styled('footer')({
  alignItems: 'center',
  display: 'flex',
  flexWrap: 'wrap',
  justifyItems: 'start',
  maxWidth: 'var(--task-card-footer-max-width)',
  // Between those breakpoints we need two rows for the links/button, because the
  // task cards get narrow
  [`@media ${devices.tabletS}`]: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  // On smaller laptop/desktop screens we need to wrap the buttons
  // since they overlap and break the footer UI when both navbar and topic/task details are open
  [`@media ${devices.laptopS}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: 0,
    paddingLeft: 'var(--spacing-small)',
  },
  [`@media ${devices.desktopS}`]: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    justifyItems: 'space-between',
    padding: '0 var(--spacing-medium)',
  },
});

export const MuiButton = styled(Button)<ButtonProps & LinkProps>({
  gap: 'var(--spacing-small)',
  paddingLeft: 'var(--spacing-small)',
  paddingRight: 'var(--spacing-medium)',
});
