import { colorSchemeSet } from 'hotelboard-indexeddb';
import React, { FC, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import useNativeCommunicationHandler from '../../hooks/native/useNativeCommunicationHandler';
import useParamHotelId from '../../hooks/router/params/useParamHotelId';
import usePrefersDarkMode from '../../hooks/usePrefersDarkMode';
import useServiceWorker from '../../hooks/useServiceWorker';
import useWisePops from '../../hooks/wisePops/useWisePops';
import postCookiesFlushEvent from '../../native-messaging/handlers/web/cookiesFlush';
import postDeviceInfoEvent from '../../native-messaging/handlers/web/deviceInfo';
import postFirebaseInfoEvent from '../../native-messaging/handlers/web/firebaseInfo';
import { useTrackPageView } from '../../services/matomo/useTrackPageView';
import { reportApiErrors } from '../../utils/error';
import AppAuthenticatedHotel from '../appAuthenticatedHotel';

/**
 * Main application entry point for authenticated users
 *
 * @returns AppAuthenticated component
 */
const AppAuthenticated: FC = () => {
  const prefersDarkMode = usePrefersDarkMode();
  const hotelId = useParamHotelId();
  useServiceWorker();
  useTrackPageView();
  useNativeCommunicationHandler();

  useEffect(() => {
    postCookiesFlushEvent();
    postDeviceInfoEvent();
    postFirebaseInfoEvent();
  }, []);

  useWisePops();

  colorSchemeSet(prefersDarkMode ? 'dark' : 'light').catch(reportApiErrors);

  // The index page is the only authenticated page that doesn't have a hotel ID in the URL
  if (hotelId === null) {
    // No need to wrap the index page with a RoqChatProvider
    return <Outlet />;
  }

  return <AppAuthenticatedHotel />;
};

export default AppAuthenticated;
