/**
 * @file contains functions for sending messages to native
 */

import { reportError } from '../../services/reporting';

import { BaseEvent, MessageEvent } from '../models/MessageEvent';

/**
 * Post a message to the native side
 *
 * @param message The message to send
 */
export const postMessageToNative = (message: MessageEvent): void => {
  if (window.ReactNativeWebView === undefined) {
    return;
  }

  try {
    const messageWithVersion: MessageEvent & BaseEvent = {
      ...message,
      version: APP_VERSION,
    };

    const dataString = JSON.stringify(messageWithVersion);
    window.ReactNativeWebView.postMessage(dataString);
  } catch (error) {
    reportError(error as Error);
  }
};
