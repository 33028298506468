/**
 * @file Handle messages sent from native app to the web app
 */

import { SOURCE_NAME } from '../constants';
import {
  MessageEventResponse,
  MessageEventResponseEvent,
} from '../models/MessageEventResponse';

/**
 * Handle messages sent from native app to the web app
 *
 * @param event          Message event
 * @param handleResponse Function to handle the response from the native app
 */
const messageEventHandler = (
  event: MessageEvent<string>,
  handleResponse: (response: MessageEventResponse) => void,
) => {
  /**
   * Since this event listener is generic we need to eliminate events other than react-native ones.
   * This check prevents unnecessary error reports because JSON.parse() would fail.
   */
  if (typeof event.data !== 'string' || event.data === 'undefined') {
    return;
  }

  try {
    const response = JSON.parse(event.data) as MessageEventResponseEvent;

    /**
     * In case the event is not from the native app, we should ignore it.
     * That can happen if the web app is opened in a webview that is not our native app.
     */
    if (response.source !== SOURCE_NAME) {
      return;
    }

    const payload = response.payload;

    handleResponse(payload);
  } catch (error) {
    reportError(error as Error);
  }
};

export default messageEventHandler;
