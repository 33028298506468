/**
 * @file Get the user's Firebase token (or null if permissions not granted)
 */

import { useEffect, useState } from 'react';

import { getFirebaseToken } from '../../utils/notifications/firebase';
import { setTokenStored } from '../../utils/storage/notifications';
import { getIsReactNativeWebView } from '../../utils/webview/helpers';

import useNativeFirebaseInfo from '../native/useNativeFirebaseInfo';

import useIsSupported from './useIsSupported';

import useNotificationPermissionStatus from './useNotificationPermissionStatus';

/**
 * Get the user's Firebase token (or null if permissions not granted)
 * (works with both native and web)
 *
 * @returns Array of unused tokens for current device
 */
const useFirebaseToken = (): string | null => {
  const [currentToken, setCurrentToken] = useState<string | null>(null);
  const firebaseInfo = useNativeFirebaseInfo();
  const isSupported = useIsSupported();
  const isWebView = getIsReactNativeWebView();
  const permission = useNotificationPermissionStatus();

  useEffect(() => {
    if (firebaseInfo?.fcmToken && isWebView === true) {
      setCurrentToken(firebaseInfo.fcmToken);
      // Also store in cookie storage, so we can retrieve it easily outside
      setTokenStored(firebaseInfo.fcmToken);
    }
  }, [firebaseInfo?.fcmToken, isWebView]);

  useEffect(() => {
    if (
      currentToken === null &&
      isWebView === false &&
      isSupported === true &&
      permission === 'granted'
    ) {
      getFirebaseToken()
        .then(setCurrentToken)
        .catch(() => setCurrentToken(null));
    }
  }, [currentToken, isSupported, isWebView, permission]);

  return currentToken;
};

export default useFirebaseToken;
