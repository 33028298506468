/**
 * @file Helper to request notification permissions
 * Works both with web and webview
 */

import postPermissionNotificationEvent from '../../native-messaging/handlers/web/permissionNotification';
import { checkWebNotificationSupport } from '../../serviceWorker';
import { reportError, reportInfo } from '../../services/reporting';
import { getIsReactNativeWebView } from '../webview/helpers';

/**
 * Ask the browser to give us permission to send push notifications
 *
 * @returns The permission level granted
 */
const requestPermission = async (): Promise<NotificationPermission | void> => {
  if (getIsReactNativeWebView()) {
    postPermissionNotificationEvent();
  } else if (checkWebNotificationSupport()) {
    try {
      const permission = await Notification.requestPermission();
      if (permission !== 'granted') {
        reportInfo(
          'Notification Permission: Permission not granted for Notification.',
        );
      }

      return permission;
    } catch (error) {
      reportError(error as Error);
      return 'denied';
    }
  } else {
    return 'denied';
  }
};

export default requestPermission;
