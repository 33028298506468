/**
 * @file  Hook for attachment file upload
 */

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery } from 'urql';

import {
  FilesAddToMessageDocument,
  FileSignedUploadPostFieldsDocument,
  Message,
} from '../../generated/graphql';

import getUploadPromises from '../../utils/attachments/getUploadPromises';
import uploadAttachments from '../../utils/attachments/uploadAttachments';
import { createAttachmentsFromFiles } from '../../utils/attachments/uploadHelpers';
import { reportApiErrors } from '../../utils/error';

/**
 * This hook manages the process of uploading files by:
 * - Dispatching necessary actions.
 * - Executing GraphQL queries and mutations.
 * - Handling errors and reporting them.
 * - Clearing file names after successful upload.
 *
 * @param clearFileNames Function to clear the file names
 * @param fileNames      Array of file names to be uploaded
 * @param filesToUpload  Array of File objects to be uploaded
 * @param parentId       ID of the parent entity to which files are being uploaded
 * @param messageId      Optional ID of the message to which files are being attached
 */
const useFileUpload = (
  clearFileNames: () => void,
  fileNames: string[],
  filesToUpload: File[],
  parentId: string,
  messageId?: Message['id'],
) => {
  const dispatch = useDispatch();
  const [, filesAddToMessageMutation] = useMutation(FilesAddToMessageDocument);
  const [{ data, error, fetching }, reExecuteQuery] = useQuery({
    pause: true,
    query: FileSignedUploadPostFieldsDocument,
    // We want to always fetch the latest signed upload post fields because they expire after a certain time
    requestPolicy: 'network-only',
    variables: {
      fileNames,
    },
  });

  /**
   * Function for attachment file upload
   *
   * @param postFields Data for POST requests received from S3
   */
  const uploadFiles = (postFields: string[]) => {
    const attachments = createAttachmentsFromFiles(filesToUpload, postFields);

    const uploadPromises = getUploadPromises(attachments, dispatch, parentId);

    uploadAttachments(
      attachments,
      dispatch,
      uploadPromises,
      filesAddToMessageMutation,
      messageId,
      parentId,
    ).catch(reportApiErrors);
  };

  useEffect(() => {
    if (error) {
      reportError(error);
    }
  }, [error]);

  useEffect(() => {
    if (fileNames.length > 0) {
      reExecuteQuery();
    }
  }, [fileNames.length, reExecuteQuery]);

  useEffect(() => {
    if (fetching === false && data) {
      clearFileNames();
      uploadFiles(data.fileSignedUploadPostFields);
    }
    // We only want this useEffect to trigger on change of data and fetching props
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.fileSignedUploadPostFields, fetching]);
};

export default useFileUpload;
