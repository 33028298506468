/**
 * @file contains helper functions for adding/removing quick filters search param
 */
import { Location } from 'react-router-dom';

import {
  SEARCH_PARAM__FILTER__QUICK,
  SEARCH_PARAM__FILTER__QUICK_STATE,
} from '../../../constants/routing/searchParams';
import { SearchQuery } from '../../../generated/graphql';
import {
  modifyUrlParams,
  removeParamsKeysFromUrl,
  replaceOrAddParamsToUrl,
} from '../../helpers/router';

/**
 * Gets url that adds `?quickFilters={filterId}` search param
 *
 * @param location      Location object from the history
 * @param quickFilterId ID of the filter
 * @returns             A string in a url format
 */
export const getAddFilterQuickUrl = (
  location: Location,
  quickFilterId: SearchQuery['id'],
): string => {
  return replaceOrAddParamsToUrl(location, { quickFilters: quickFilterId });
};

/**
 * Gets url that removes `?quickFilters={filterId}` search param
 *
 * @param location Location object from the history
 * @returns        A string in a url format
 */
export const getRemoveFilterQuickUrl = (location: Location): string => {
  return removeParamsKeysFromUrl(location, SEARCH_PARAM__FILTER__QUICK);
};

/**
 * Get url to add/remove quick filter
 *
 * @param location      Location object from the history
 * @param isActive      Whether the filter is active
 * @param quickFilterId Id of the filter
 * @returns             Url to add/remove quick filter
 */
export const getFilterQuickUrl = (
  location: Location,
  isActive: boolean,
  quickFilterId: SearchQuery['id'],
): string => {
  return isActive
    ? getRemoveFilterQuickUrl(location)
    : getAddFilterQuickUrl(location, quickFilterId);
};

/**
 * Get url for opening or closing the quick filters based on current location
 *
 * @param location Location object from the history
 * @param isOpen   Whether the filter is open
 * @returns        A string in a url format
 */
export const getQuickFiltersStateToggleUrl = (
  location: Location,
  isOpen: boolean,
): string => {
  const newUrl = modifyUrlParams(location, {
    params: isOpen ? {} : { [SEARCH_PARAM__FILTER__QUICK_STATE]: 'open' },
    paramsToRemove: isOpen ? [SEARCH_PARAM__FILTER__QUICK_STATE] : [],
  });

  return newUrl;
};
