import { Paper, PaperProps, Stack, styled } from '@mui/material';

import { colorNeutral } from '../../styles/mui/colors';

type Props = {
  'data-is-dragging': boolean;
};

export const MuiPaper = styled(Paper)<PaperProps & Props>(
  ({ theme, ...props }) => ({
    '&:focus-visible': {
      borderColor: colorNeutral[600],
    },
    '&:hover': {
      boxShadow: theme.shadows[3],
    },
    border: '1px solid transparent',
    borderRadius: 'var(--border-radius-huge)',
    boxShadow: theme.shadows[props['data-is-dragging'] === true ? 8 : 1],
    flexShrink: 0,
    padding: 'var(--spacing-medium) 0',
    transition:
      'border-color var(--preference-transition-duration-normal), box-shadow var(--preference-transition-duration-normal)',
  }),
);

export const MuiStack = styled(Stack)({
  // We need to set horizontal padding per element since footer has different padding based on screen size
  // @see src/components/Task/Footer/styled.ts for more details
  '& > *': {
    padding: '0 var(--spacing-medium)',
  },
  gap: 'var(--spacing-medium)',
});
