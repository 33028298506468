/**
 * @file Get whether push notification reception is supported
 */

import getIsPushSupported from '../../utils/notifications/getIsPushSupported';

import useIsFirebaseSupported from './useIsFirebaseSupported';

/**
 * Get whether push notification reception is supported
 * (includes Firebase support check and permission check)
 *
 * @returns Whether it is
 */
const useIsSupported = (): boolean => {
  const hasFirebaseSupport = useIsFirebaseSupported();
  const hasPushSupport = getIsPushSupported();

  return hasPushSupport === true && hasFirebaseSupport === true;
};

export default useIsSupported;
