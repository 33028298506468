import React, { FC } from 'react';

import BaseHeaderMobile from '../../../components/Header/BaseHeaderMobile';
import useParamTopicEditMode from '../../../hooks/router/params/useParamTopicEditMode';
import translate from '../../../i18n/translate';

import { getTopiHeaderTitle } from './helpers';

/**
 * Header component for topic full view on mobile
 *
 * @returns The component itself
 */
const TopicFullHeaderMobile: FC = () => {
  const topicEditMode = useParamTopicEditMode();

  const title = getTopiHeaderTitle(topicEditMode);

  return (
    <BaseHeaderMobile
      backUrl={true}
      contentRight={null}
      title={translate(title)}
    />
  );
};

export default TopicFullHeaderMobile;
