/**
 * @file contains helper function for file upload to AWS S3
 */

import { Dispatch } from 'redux';

import { CombinedError, UseMutationExecute } from 'urql';

import { FilesAddToMessageMutation, Message } from '../../generated/graphql';
import { AttachmentDraftPreUploadMapEntry } from '../../models/attachment';
import { DraftActionAttachment } from '../../models/draftAttachment';
import { removeDraftAttachmentRequest } from '../../store/actions/draftsAttachments';
import { reportApiErrors } from '../error';

/**
 * Uploads attachments to an AWS S3 bucket
 * If message already exists, associates the attachments with the message,
 * otherwise, the attachments are uploaded with create message mutation.
 *
 * @param attachments               A map of attachment keys to their pre-upload metadata.
 * @param dispatch                  A dispatch function to handle draft attachment actions.
 * @param filesUpload               An array of promises representing the file upload operations.
 * @param filesAddToMessageMutation A mutation function to associate uploaded files with a message.
 * @param messageId                 The ID of the message to associate the attachments with, if it exists.
 * @param parentId                  The ID of the parent entity for the draft attachment actions.
 * @returns                         A promise that resolves when all attachments are uploaded and associated with the message (if applicable).
 */
const uploadAttachments = async (
  attachments: Map<string, AttachmentDraftPreUploadMapEntry>,
  dispatch: Dispatch<DraftActionAttachment>,
  filesUpload: Promise<void | Response | undefined>[],
  filesAddToMessageMutation: UseMutationExecute<FilesAddToMessageMutation>,
  messageId: Message['id'] | undefined,
  parentId: string,
) => {
  // Wait for all files to be uploaded
  await Promise.all(filesUpload);

  // Get keys of uploaded attachments
  const keys = Array.from(attachments.keys());

  // If message ID exists, associate attachments with the message (if not message is not created yet)
  if (messageId) {
    try {
      await filesAddToMessageMutation({ keys, messageId });
      keys.forEach(key =>
        dispatch(removeDraftAttachmentRequest(parentId, key)),
      );
    } catch (error) {
      reportApiErrors(error as CombinedError);
    }
  }
};

export default uploadAttachments;
