/**
 * @file Post event to native app to request notification permission
 */
import { REQUEST__PERMISSION_NOTIFICATION } from '../../constants';
import { postMessageToNative } from '../../helpers/postMessageToNative';

/**
 * Post event to native app to request notification permission
 */
const postPermissionNotificationEvent = (): void => {
  postMessageToNative({
    eventName: REQUEST__PERMISSION_NOTIFICATION,
  });
};

export default postPermissionNotificationEvent;
