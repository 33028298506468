/**
 * @file Post event to native app to handle attachment action
 */
import { REQUEST__ATTACHMENT_ACTION } from '../../constants';
import { postMessageToNative } from '../../helpers/postMessageToNative';
import { PayloadData } from '../../models/PayloadData';

/**
 * Post event to native app to handle attachment action
 *
 * @param actionType    Attachment action type (download/preview)
 * @param attachmentUrl Attachment URL
 */
const postAttachmentActionEvent = (
  actionType: PayloadData[typeof REQUEST__ATTACHMENT_ACTION]['type'],
  attachmentUrl: PayloadData[typeof REQUEST__ATTACHMENT_ACTION]['url'],
): void => {
  postMessageToNative({
    data: {
      type: actionType,
      url: attachmentUrl,
    },
    eventName: REQUEST__ATTACHMENT_ACTION,
  });
};

export default postAttachmentActionEvent;
