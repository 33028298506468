/**
 * @file Check whether the browser supports Firebase Messaging
 */

import { isSupported } from 'firebase/messaging';
import { useEffect, useState } from 'react';

import { getIsReactNativeWebView } from '../../utils/webview/helpers';

/**
 * Check whether the browser supports Firebase Messaging
 *
 * @returns Whether it's supported or not
 */
const useIsFirebaseSupported = (): boolean | null => {
  const [hasSupport, setHasSupport] = useState<null | boolean>(null);
  const isReactNativeWebView = getIsReactNativeWebView();

  useEffect(() => {
    if (isReactNativeWebView) {
      setHasSupport(true);
    } else {
      isSupported()
        .then(setHasSupport)
        .catch(() => setHasSupport(false));
    }
  }, [isReactNativeWebView]);

  return hasSupport;
};

export default useIsFirebaseSupported;
