import React, { FC } from 'react';

import SegmentedButton from '../../MUI/SegmentedButton';

import * as Styled from './styled';

type HeaderSegmentedButtonProps = {
  to: string;
  isActive: boolean;
};

export type Props = {
  buttons: HeaderSegmentedButtonProps[];
  className?: string;
};

/**
 * Segmented buttons that are show within the header on desktop, and below the header on mobile
 *
 * @param props           Props passed to the component
 * @param props.buttons   Props passed to segmented buttons
 * @param props.className Additional classes to be added to the wrapper (to position the element within a grid)
 * @returns               The component itself
 */
const HeaderSegmentedButtons: FC<Props> = ({ buttons, className }) => {
  if (buttons.length === 0) {
    return null;
  }

  return (
    <Styled.SegmentedButtonGroupWrapper className={className}>
      <Styled.SegmentedButtonGroup>
        {buttons.map(buttonProps => (
          <SegmentedButton key={buttonProps.to} {...buttonProps} />
        ))}
      </Styled.SegmentedButtonGroup>
    </Styled.SegmentedButtonGroupWrapper>
  );
};

export default HeaderSegmentedButtons;
