import React, { FC } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { getQuickFiltersStateToggleUrl } from '../../../../routes/urls/searchParams/quickFilters';
import QuickFiltersContainer from '../../../quickFilters';

import { getIsFilterActive, getIsQuickFilterOpen } from './helpers';
import * as Styled from './styled';

/**
 * The feed/kanban quick filters to filter out messages and tasks
 *
 * @returns The layout to use
 */
const FeedKanbanQuickFilters: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const isFilterActive = getIsFilterActive(location);
  const isQuickFilterOpen = getIsQuickFilterOpen(location);

  const url = getQuickFiltersStateToggleUrl(location, isQuickFilterOpen);

  return (
    <>
      <Styled.QuickFiltersButton
        badgeCount={isFilterActive ? 1 : 0}
        isSelected={isQuickFilterOpen}
        requestChange={() => navigate(url, { replace: true })}
      />
      {isQuickFilterOpen && (
        <Styled.QuickFiltersWrapper>
          <QuickFiltersContainer />
        </Styled.QuickFiltersWrapper>
      )}
    </>
  );
};

export default FeedKanbanQuickFilters;
