/**
 * @file Hook to get the ID of the current device
 */

import { useEffect, useState } from 'react';

import getDeviceId from '../../utils/device/getDeviceId';

import { getIsReactNativeWebView } from '../../utils/webview/helpers';
import useNativeDeviceInfo from '../native/useNativeDeviceInfo';

/**
 * Hook to get the ID of the current device
 *
 * @returns Current device id or null
 */
const useDeviceId = (): string | null => {
  const deviceInfo = useNativeDeviceInfo();
  const [deviceId, setDeviceId] = useState<string | null>(null);

  const deviceIdNative = deviceInfo?.deviceId ?? null;

  useEffect(() => {
    const isReactNativeWebView = getIsReactNativeWebView();

    if (isReactNativeWebView) {
      setDeviceId(deviceIdNative);
    } else {
      setDeviceId(getDeviceId());
    }
  }, [deviceIdNative]);

  return deviceId;
};

export default useDeviceId;
