/**
 * @file contains helpers for attachment file names and extensions
 */

/**
 * Get file extension from file name
 *
 * @todo Handle file names without extensions
 * @param filename Name of file
 * @returns        File extension or null
 */
export const getAttachmentExtension = (
  filename: string,
): string | undefined => {
  const extension =
    filename.split('.').length > 1 ? filename.split('.').pop() : undefined;

  return extension?.toLowerCase();
};

/**
 * Get name from full file name (with extension)
 *
 * @todo Handle file names without extensions
 * @param filename Name of file
 * @returns        File name
 */
export const getFileName = (filename: string): string => {
  const splitFilename = filename.split('.');

  splitFilename.pop();

  return splitFilename.join('.');
};

/**
 * Append _file to filenames reserved for Windows and replace reserved chars with underscore
 * (This function mutates original File)
 *
 * @param file File that we are renaming
 */
export const formatFilename = (file: File) => {
  // eslint-disable-next-line no-control-regex
  const filenameReservedRegex = /[<>:"/\\|?*+\u0000-\u001F]/g;
  const windowsReservedNameRegex =
    /^((?:COM[0-9]|CON|LPT[0-9]|NUL|PRN|AUX|com[0-9]|con|lpt[0-9]|nul|prn|aux)(\.|$)|\s|[.]{2,})[^\\/:*"?<>|]{1,254}$/i;
  let name = file.name;

  if (windowsReservedNameRegex.test(file.name)) {
    name = getFileName(name) + '_file.' + getAttachmentExtension(name);
  }

  if (filenameReservedRegex) {
    name = name.replace(filenameReservedRegex, '_');
  }

  const splitName = getFileName(name);
  const extension = getAttachmentExtension(name);

  /**
   * Since File is read-only we need to mutate existing object
   */
  Object.defineProperty(file, 'name', {
    value: splitName + '.' + extension,
  });
};
