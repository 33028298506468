/**
 * @file contains helper function for the FeedFooter component
 */
import { Topic } from '../../../../../generated/graphql';
import { createFeedTopicDetailsPath } from '../../../../paths/feed';
import { createKanbanTopicDetailsPath } from '../../../../paths/kanban';

/**
 * Topic details paths
 */
const detailsPaths = [
  createKanbanTopicDetailsPath(),
  createFeedTopicDetailsPath(),
];

/**
 * Get whether the create button should be shown
 *
 * @param canCreateMessage Whether the current user can create a message
 * @param currentPath      The current path
 * @param topicId          Current topic ID
 * @returns                Whether the create button should be shown
 */
export const getShouldShowButtonCreate = (
  canCreateMessage: boolean,
  currentPath: string | null,
  topicId: Topic['id'] | null,
): boolean => {
  const isDetails = detailsPaths.some(path => currentPath?.includes(path));

  return canCreateMessage && topicId !== null && isDetails === false;
};
