/**
 * @file contains hook for subscribing to push notifications on native
 */
import { useMutation } from 'urql';

import { PushSubscribeDocument } from '../../generated/graphql';

import { reportApiErrors } from '../../utils/error';

import { getHasUnsubscribed } from '../../utils/storage/notifications';

import useNativeDeviceInfo from './useNativeDeviceInfo';
import useNativeFirebaseInfo from './useNativeFirebaseInfo';

/**
 * Get the device info and firebase info and subscribe to push notifications
 *
 * @returns The function to subscribe to push notifications
 */
const useNativePushSubscribe = () => {
  const [, pushSubscribe] = useMutation(PushSubscribeDocument);
  const deviceInfo = useNativeDeviceInfo();
  const firebaseInfo = useNativeFirebaseInfo();
  const hasUnsubscribed = getHasUnsubscribed();

  /**
   * Subscribe to push notifications
   */
  const subscribeToPushNotifications = () => {
    if (
      hasUnsubscribed === true ||
      deviceInfo === null ||
      firebaseInfo === null
    ) {
      return;
    }

    const { deviceId, deviceName, deviceType } = deviceInfo;
    const { fcmToken: token } = firebaseInfo;

    if (deviceId === null || token === null) {
      return;
    }

    pushSubscribe({
      deviceId,
      deviceName,
      deviceType,
      token,
    }).catch(reportApiErrors);
  };

  return subscribeToPushNotifications;
};

export default useNativePushSubscribe;
