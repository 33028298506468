/**
 * @file Get device info and firebase token from native app
 */

import { useSelector } from 'react-redux';

import AppState from '../../models/state';
import { PayloadDataResponse } from '../../native-messaging/models/PayloadData';

/**
 * Get device info and firebase token from native app
 *
 * @returns Object containing device info and firebase token
 */
const useNativeDeviceInfo = ():
  | PayloadDataResponse['deviceInfoResponse']
  | null => {
  const deviceInfo = useSelector<AppState, AppState['nativeApp']['deviceInfo']>(
    state => state.nativeApp.deviceInfo,
  );

  return deviceInfo;
};

export default useNativeDeviceInfo;
