/**
 * @file Reducer for attachment local state
 */
import {
  DRAFTS__ATTACHMENT__ADD,
  DRAFTS__ATTACHMENT__CLEAR,
  DRAFTS__ATTACHMENT__INIT,
  DRAFTS__ATTACHMENT__REMOVE,
} from '../../constants/actionTypes';
import {
  DraftActionAttachment,
  DraftActionAttachmentAdd,
  DraftActionAttachmentClear,
  DraftActionAttachmentInit,
  DraftActionAttachmentRemove,
  DraftAttachmentState,
} from '../../models/draftAttachment';
import attachmentsDraftClone from '../../utils/attachmentsDraftClone';
import initAttachmentsDraft from '../../utils/initAttachmentsDraft';

type Reducer = (
  state: DraftAttachmentState,
  action: DraftActionAttachment,
) => DraftAttachmentState;

const initialState: DraftAttachmentState = new Map();

/**
 * A request was made to create a new draft
 *
 * @param stateCurrent Current drafts state
 * @param action       The action that took place
 * @returns            The updated state
 */
const onAttachmentInitRequest = (
  stateCurrent: DraftAttachmentState,
  action: DraftActionAttachmentInit,
): DraftAttachmentState => {
  const { parentId } = action;
  const stateNext = attachmentsDraftClone(stateCurrent);

  // Only create a draft if parentId doesn't have one already
  if (stateNext.has(parentId) === false) {
    const draft = initAttachmentsDraft(parentId);
    stateNext.set(parentId, draft);
  }

  return stateNext;
};

/**
 *
 * CREATE callbacks
 *
 */

/**
 * A request was made to add an attachment
 *
 * @param stateCurrent Current drafts state
 * @param action       The action that took place
 * @returns            The updated state
 */
export const onAttachmentAddRequest = (
  stateCurrent: DraftAttachmentState,
  action: DraftActionAttachmentAdd,
): DraftAttachmentState => {
  const { parentId, attachment } = action;
  const stateNext = attachmentsDraftClone(stateCurrent);
  const draftNext = stateNext.get(parentId);

  if (draftNext) {
    draftNext.attachments.set(attachment.key, {
      extension: attachment.extension?.toLowerCase(),
      file: attachment.file,
      isDraft: attachment.isDraft,
      isUploading: attachment.isUploading,
      name: attachment.name,
    });
  }

  return stateNext;
};

/**
 *
 * DELETE callbacks
 *
 */

/**
 * A request was made to remove an attachment
 *
 * @param stateCurrent Current drafts state
 * @param action       The action that took place
 * @returns            The updated state
 */
export const onAttachmentRemoveRequest = (
  stateCurrent: DraftAttachmentState,
  action: DraftActionAttachmentRemove,
): DraftAttachmentState => {
  const { parentId, key } = action;
  const stateNext = attachmentsDraftClone(stateCurrent);
  const draftNext = stateNext.get(parentId);

  if (draftNext) {
    if (Array.isArray(key)) {
      /** @todo k is defined as a string, so TS is complaining here */
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      key.forEach(k => draftNext.attachments.delete(k));
    } else {
      draftNext.attachments.delete(key);
    }
  }

  return stateNext;
};

/**
 * A request was made to clear a attachment draft
 *
 * @param stateCurrent The current state
 * @param action       The action that took place
 * @returns            Appropriately modified state
 */
const onAttachmentClearRequest = (
  stateCurrent: DraftAttachmentState,
  action: DraftActionAttachmentClear,
): DraftAttachmentState => {
  const { parentId } = action;
  const stateNext = attachmentsDraftClone(stateCurrent);

  const draft = initAttachmentsDraft(parentId);
  stateNext.set(parentId, draft);

  return stateNext;
};

const mapping = new Map<string, Reducer>([
  [DRAFTS__ATTACHMENT__ADD, onAttachmentAddRequest],
  [DRAFTS__ATTACHMENT__CLEAR, onAttachmentClearRequest],
  [DRAFTS__ATTACHMENT__INIT, onAttachmentInitRequest],
  [DRAFTS__ATTACHMENT__REMOVE, onAttachmentRemoveRequest],
]);

/**
 * Drafts Attachments reducer
 *
 * @param state  Current state or initial state if undefined
 * @param action Draft attachment action
 * @returns      New state
 */
const draftsAttachments = (
  state = initialState,
  action: DraftActionAttachment,
): DraftAttachmentState => {
  const reducer = mapping.get(action.type);
  return reducer ? reducer(state, action) : state;
};

export const actionTypes = Array.from(mapping.keys());
export default draftsAttachments;
