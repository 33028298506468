import React, { FC, ReactNode, useId } from 'react';
import { LinkProps } from 'react-router-dom';
import { CombinedError } from 'urql';

import { TopicBasicFragment } from '../../generated/graphql';
import translate from '../../i18n/translate';
import TemplateTopicForm from '../Forms/TemplateTopicForm';
import MetaContainerInline, {
  Props as PropsInline,
} from '../MetaContainerInline';
import MetaContainerPopup, { Props as PropsPopup } from '../MetaContainerPopup';

type Props = {
  className?: string;
  dataTopicAll: TopicBasicFragment[];
  dataTopicCurrent: TopicBasicFragment['id'] | null;
  dataTopicSaved: TopicBasicFragment['id'] | null;
  dataSearchValue: string;
  error?: CombinedError;
  inputCustom?: ReactNode;
  isActive: boolean;
  isEditMode: boolean;
  isLoading: boolean;
  linkToggleProps?: LinkProps;
  onSearch: (term: string) => void;
  requestTopicClose?: () => void;
  requestTopicSave: () => void;
  requestTopicChange: (topicId: TopicBasicFragment['id']) => void;
};

/**
 * Topic popup for the compose block
 *
 * @param props                    Props passed to the component
 * @param props.className          styled-components generated class name, needed for styling
 * @param props.dataTopicAll       All available topics
 * @param props.dataTopicCurrent   Currently selected topic
 * @param props.dataTopicSaved     Currently saved topic
 * @param props.dataSearchValue    <input type="search" /> value attribute
 * @param props.error              Topics fetching error
 * @param props.inputCustom        Component to use for overview in mobile view
 * @param props.isActive           Whether the component should be visible
 * @param props.isEditMode         Whether Compose is in template edit mode
 * @param props.isLoading          Whether the data is still being loaded
 * @param props.linkToggleProps    Props for the toggle link
 * @param props.onSearch           Callback to invoke when search term is changed
 * @param props.requestTopicClose  Request to close topic popup
 * @param props.requestTopicSave   Request that the current topic is saved
 * @param props.requestTopicChange Request that selected topic is changed
 * @returns                        The component itself
 */
const MetaTopic: FC<Props> = ({
  className,
  dataSearchValue,
  dataTopicAll,
  dataTopicCurrent,
  dataTopicSaved,
  error,
  inputCustom,
  isActive,
  isEditMode,
  isLoading,
  linkToggleProps = null,
  onSearch,
  requestTopicSave,
  requestTopicClose,
  requestTopicChange,
}) => {
  /**
   * We need to check if currently selected topic is equal to draft/saved topic
   * so we can disable save button if they are equal.
   */
  const isSaveDisabled = dataTopicCurrent === dataTopicSaved;

  const idForm = useId();
  const idHeading = useId();

  // Popup top buttons' labels
  const labelConfirm = translate('GENERAL__SAVE');
  const labelClose = translate('GENERAL__CLOSE');

  const textPlaceholder = translate('COMPOSE__PLACEHOLDER__TOPIC');
  const textHeading = textPlaceholder;

  const topicTitle =
    dataTopicAll.find(topic => topic.id === dataTopicSaved)?.title ?? '';

  // Props used for both inline and popup meta
  const propsCommon: PropsInline | PropsPopup = {
    className,
    idForm,
    idHeading,
    isSaveDisabled,
    labelClose,
    labelConfirm,
    onClose: requestTopicClose,
    textHeading,
  };

  const children = (
    <TemplateTopicForm
      ariaDescribedBy={idHeading}
      ariaLabelledBy={idHeading}
      dataSearchValue={dataSearchValue}
      dataTopicAll={dataTopicAll}
      dataTopicCurrent={dataTopicCurrent}
      error={error}
      idForm={idForm}
      isLoading={isLoading}
      onSearch={onSearch}
      requestTopicChange={requestTopicChange}
      requestTopicSave={requestTopicSave}
    />
  );

  if (linkToggleProps === null) {
    return isActive ? (
      <MetaContainerInline {...propsCommon}>{children}</MetaContainerInline>
    ) : null;
  }

  return (
    <MetaContainerPopup
      {...propsCommon}
      inputCustom={inputCustom}
      linkProps={linkToggleProps}
      placeholder={`${textPlaceholder} *`}
      shouldDisablePopup={isEditMode}
      shouldShowPopup={isActive}
      value={topicTitle}
    >
      {children}
    </MetaContainerPopup>
  );
};

export default MetaTopic;
