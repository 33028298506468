/**
 * @file contains redux actions related to message events from the native app
 */

import {
  NATIVE_APP__DEVICE_INFO__SET,
  NATIVE_APP__DOWNLOAD_INFO__SET,
  NATIVE_APP__FIREBASE_INFO__SET,
} from '../../constants/actionTypes';
import { PayloadDataResponse } from '../../native-messaging/models/PayloadData';

/**
 * Generate the action for setting device info
 *
 * @param payload The device info
 * @returns       The generated action
 */
export const setNativeAppDeviceInfo = (
  payload: PayloadDataResponse['deviceInfoResponse'],
) => ({
  payload,
  type: NATIVE_APP__DEVICE_INFO__SET,
});

/**
 * Generate the action for setting download info
 *
 * @param payload The download info
 * @returns       The generated action
 */
export const setNativeAppDownloadInfo = (
  payload: PayloadDataResponse['attachmentActionResponse'],
) => ({
  payload,
  type: NATIVE_APP__DOWNLOAD_INFO__SET,
});

/**
 * Generate the action for setting firebase info
 *
 * @param payload The firebase info
 * @returns       The generated action
 */
export const setNativeAppFirebaseInfo = (
  payload: PayloadDataResponse['firebaseInfoResponse'],
) => ({
  payload,
  type: NATIVE_APP__FIREBASE_INFO__SET,
});
