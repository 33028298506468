/**
 * @file  Hook for attachment upload and removal in Compose
 */
import { ChangeEventHandler, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Message } from '../../generated/graphql';
import {
  getAttachmentErrors,
  showFileToastError,
} from '../../utils/attachments/errorHandling';
import { formatFilename } from '../../utils/attachments/filenameHelpers';
import filterFiles from '../../utils/attachments/filterFiles';
import { getAttachmentInputAccept } from '../../utils/attachments/uploadHelpers';

import useAttachmentDraftState from './useAttachmentDraftState';
import useFileUpload from './useFileUpload';

/**
 * Hook for handling file upload
 *
 * @param parentId   The parent of current draft attachments local state
 * @param messageId  Id of the current message
 * @param nrUploaded Number of files already uploaded or being uploaded
 * @returns          Object with necessary props for handling file upload
 */
const useAttachmentsUpload = (
  parentId: string,
  messageId?: Message['id'],
  nrUploaded = 0,
) => {
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const [fileNames, setFileNames] = useState<string[]>([]);
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const { attachmentsDraft, clearDraft, removeDraft } =
    useAttachmentDraftState(parentId);

  /**
   * Clears the list of file names by setting it to an empty array.
   */
  const clearFileNames = () => {
    setFileNames([]);
  };

  useFileUpload(clearFileNames, fileNames, filesToUpload, parentId, messageId);

  /**
   * Handles the change event for file input elements.
   *
   * This function processes the selected files, checks for attachment errors,
   * filters the files, formats their filenames, and updates the state with the
   * valid files to be uploaded. It also clears the input value to allow the user
   * to select the same file(s) consecutively.
   *
   * @param event The change event triggered by the file input element
   */
  const onChange: ChangeEventHandler<HTMLInputElement> = event => {
    const files = inputRef.current?.files
      ? Array.from(inputRef.current.files)
      : [];

    if (files.length > 0) {
      const numberOfFiles = attachmentsDraft.size + nrUploaded;

      const attachmentErrors = getAttachmentErrors(files, numberOfFiles);

      if (attachmentErrors !== null) {
        showFileToastError(dispatch, attachmentErrors);
      }

      const filteredFiles = filterFiles(files, numberOfFiles);

      if (filteredFiles.length > 0) {
        filteredFiles.forEach(file => formatFilename(file));

        setFileNames(filteredFiles.map(file => file.name));
        setFilesToUpload(filteredFiles);
      }

      event.target.value = '';
    }
  };

  const inputProps = {
    accept: getAttachmentInputAccept(),
    multiple: true,
    onChange,
    ref: inputRef,
  };

  const attachmentsFormatted = Array.from(attachmentsDraft, ([key, draft]) => ({
    ...draft,
    extension: draft.extension,
    isDraft: true,
    key,
    name: draft.file.name,
  }));

  return {
    attachmentsDraft,
    attachmentsFormatted,
    handleClearDraft: clearDraft,
    handleFileRemove: removeDraft,
    inputProps,
  };
};

export default useAttachmentsUpload;
