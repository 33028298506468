/**
 * @file Hook for managing ES search query and its results
 */

import { useNavigate } from 'react-router-dom';
import { useQuery } from 'urql';

import {
  MessageRankedSearchDocument,
  SearchParams,
} from '../../../generated/graphql';
import useParamSearchTerm from '../../router/params/useParamSearchTerm';
import { extractError } from '../helpers';
import { UseESQueryReturn } from '../types';

import {
  getHasMore,
  getIsCurrentOperation,
  getIsEmpty,
  getIsValidQuery,
  getLoadMore,
} from './helpers';
import useSearchFormParams from './useSearchFormParams';
import useSearchPageSync from './useSearchTextUrlCurrent';
import useSearchTextUrlNext from './useSearchTextUrlNext';

// Never actually used,
// since we pause making a request when params are missing from the URL
// But TypeScript complains otherwise
const paramsDefault: SearchParams = { from: 0, queryString: '', size: 0 };

/**
 * Hook for managing ES search query and its results
 *
 * @returns Data needed from making an ES query.
 */
const useEsTextQuery = (): UseESQueryReturn => {
  const navigate = useNavigate();
  const searchTerm = useParamSearchTerm();
  const paramsUrl = useSearchFormParams();
  const urlNext = useSearchTextUrlNext();

  const isValidQuery = getIsValidQuery(paramsUrl, searchTerm);

  const [{ data, error, fetching, operation }] = useQuery({
    pause: isValidQuery === false,
    query: MessageRankedSearchDocument,
    requestPolicy: 'network-only',
    variables: { params: paramsUrl ?? paramsDefault },
  });

  const hasMore = getHasMore(data);
  const isCurrentOperationQuery = getIsCurrentOperation(operation, searchTerm);
  const isEmpty = getIsEmpty(data, fetching);
  const loadMore = getLoadMore(fetching, hasMore, () => navigate(urlNext));
  const nodes = data?.messageRankedSearch.nodes ?? [];
  /**
   * As an example:
   * 1. Search A => results A
   * 2. Search B => results A (from previous search) => results B (response received)
   *
   * While the variables ask for Search B, the operation still references the previous one (Search A)
   *
   * By checking `isCurrentOperationQuery`, we make sure that the data
   * is reflecting our current search, and not the previous one.
   */
  const { messages = [], total = 0 } = isCurrentOperationQuery
    ? {
        messages: nodes,
        total: data?.messageRankedSearch.searchInfo.total ?? 0,
      }
    : {};

  useSearchPageSync(data, fetching);

  return {
    error: extractError(isValidQuery, fetching, error),
    isEmpty,
    isLoading: fetching,
    loadMore,
    messages,
    searchTerm,
    total,
  };
};

export default useEsTextQuery;
