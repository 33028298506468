/**
 * @file contains helper function for determining whether push notifications are supported
 */

import { checkWebPushSupport } from '../../serviceWorker';
import { getIsReactNativeWebView } from '../webview/helpers';

const isReactNativeWebView = getIsReactNativeWebView();

/**
 * Get whether push notifications are supported
 *
 * @returns Whether push notifications are supported
 */
const getIsPushSupported = (): boolean => {
  if (isReactNativeWebView) {
    return true;
  }

  return checkWebPushSupport();
};

export default getIsPushSupported;
