/**
 * @file Get firebase token from native app
 */

import { useSelector } from 'react-redux';

import AppState from '../../models/state';
import { PayloadDataResponse } from '../../native-messaging/models/PayloadData';

/**
 * Get firebase token from native app
 *
 * @returns Object containing firebase token
 */
const useNativeFirebaseInfo = ():
  | PayloadDataResponse['firebaseInfoResponse']
  | null => {
  const firebaseInfo = useSelector<
    AppState,
    AppState['nativeApp']['firebaseInfo']
  >(state => state.nativeApp.firebaseInfo);

  return firebaseInfo;
};

export default useNativeFirebaseInfo;
