/**
 * @file contains consts for message events
 */
// Response events sent from native to web
export const RESPONSE__ATTACHMENT_ACTION = 'attachmentActionResponse';
export const RESPONSE__DEVICE_INFO = 'deviceInfoResponse';
export const RESPONSE__FIREBASE_INFO = 'firebaseInfoResponse';
export const RESPONSE__NOTIFICATION_PERMISSION =
  'notificationPermissionResponse';

// Request events sent from web to native
export const REQUEST__ATTACHMENT_ACTION = 'attachmentActionRequest';
export const REQUEST__COOKIES_FLUSH = 'cookiesFlushRequest';
export const REQUEST__CUSTOM_SETTINGS = 'customSettingsRequest';
export const REQUEST__DEVICE_INFO = 'deviceInfoRequest';
export const REQUEST__FIREBASE_INFO = 'firebaseInfoRequest';
export const REQUEST__PERMISSION_NOTIFICATION = 'permissionNotificationRequest';
export const REQUEST__PERMISSION_CAMERA = 'permissionCameraRequest';

export const SOURCE_NAME = 'hotelboard-native';
