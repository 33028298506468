/**
 * @file Store and retrieve notifications info
 * Using cookies because localStorage and sessionStorage are unavailable in webview
 */

import cookie from 'js-cookie';

import { PREFERENCE_COOKIE_OPTIONS_INTERNAL } from '../../constants/preferences';
import {
  KEY__PUSH_DISMISSED_GREEN,
  KEY__PUSH_DISMISSED_PURPLE,
  KEY__PUSH_DISMISSED_RED,
  KEY__PUSH_PAUSED,
  KEY__PUSH_TOKEN,
  KEY__PUSH_UNSUBSCRIBED,
} from '../../constants/sessionStorage';
import { NotificationModal } from '../../containers/modals/Notifications/Main/helpers';
import postCookiesFlushEvent from '../../native-messaging/handlers/web/cookiesFlush';

/**
 * Cookie values are stored as strings
 */
const valueTrue = true.toString();
const valueFalse = false.toString();

/**
 * Get whether the user has previously dismissed green permissions modal
 * during this session
 *
 * @returns Whether it's true
 */
export const getModalGreenDismissed = (): boolean => {
  return cookie.get(KEY__PUSH_DISMISSED_GREEN) === valueTrue;
};

/**
 * Get whether the user has previously dismissed purple permissions modal
 * during this session
 *
 * @returns Whether it's true
 */
export const getModalPurpleDismissed = (): boolean => {
  return cookie.get(KEY__PUSH_DISMISSED_PURPLE) === valueTrue;
};

/**
 * Get whether the user has previously dismissed red permissions modal
 * during this session
 *
 * @returns Whether it's true
 */
export const getModalRedDismissed = (): boolean => {
  return cookie.get(KEY__PUSH_DISMISSED_RED) === valueTrue;
};

/**
 * Set whether the user has previously dismissed a permissions modal
 *
 * @param which Which modal was dismissed
 */
export const setModalDismissed = (which: NotificationModal | null): void => {
  const mapping: Record<NotificationModal, string> = {
    green: KEY__PUSH_DISMISSED_GREEN,
    purple: KEY__PUSH_DISMISSED_PURPLE,
    red: KEY__PUSH_DISMISSED_RED,
  };

  if (which !== null) {
    cookie.set(mapping[which], valueTrue, PREFERENCE_COOKIE_OPTIONS_INTERNAL);
  }
};

/**
 * Set whether to pause auto subscribing to push notifications
 *
 * @returns Whether to do pause
 */
export const getSubscriptionPaused = (): boolean => {
  return cookie.get(KEY__PUSH_PAUSED) === valueTrue;
};

/**
 * Set whether to pause auto subscribing to push notifications
 *
 * @param value Whether to do pause
 */
export const setSubscriptionPaused = (value: boolean): void => {
  cookie.set(KEY__PUSH_PAUSED, value ? valueTrue : valueFalse);
};

/**
 * Get whether the user has previously unsubscribed
 * from push notifications already
 *
 * @returns Whether it's true
 */
export const getHasUnsubscribed = (): boolean => {
  return cookie.get(KEY__PUSH_UNSUBSCRIBED) === valueTrue;
};

/**
 * Set that the user has unsubscribed from
 */
export const setHasUnsubscribed = (): void => {
  cookie.set(
    KEY__PUSH_UNSUBSCRIBED,
    valueTrue,
    PREFERENCE_COOKIE_OPTIONS_INTERNAL,
  );
  postCookiesFlushEvent();
};

/**
 * Get Firebase token stored in the cookies
 *
 * @returns The token, if found, null otherwise
 */
export const getTokenStored = (): string | null => {
  return cookie.get(KEY__PUSH_TOKEN) ?? null;
};

/**
 * Store Firebase token in cookies
 *
 * @param token The token to store
 */
export const setTokenStored = (token: string): void => {
  cookie.set(KEY__PUSH_TOKEN, token, PREFERENCE_COOKIE_OPTIONS_INTERNAL);
};

/**
 * Remove everything related to notifications from storage
 */
export const resetStorage = (): void => {
  cookie.remove(KEY__PUSH_DISMISSED_GREEN);
  cookie.remove(KEY__PUSH_DISMISSED_PURPLE);
  cookie.remove(KEY__PUSH_DISMISSED_RED);
  cookie.remove(KEY__PUSH_TOKEN);
  cookie.remove(KEY__PUSH_UNSUBSCRIBED);
  postCookiesFlushEvent();
};
