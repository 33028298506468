import { Link } from 'react-router-dom';
import { css, styled } from 'styled-components';

import devices from '../../../styles/devices';

const kpiSingleStyles = css`
  align-items: center;
  align-self: center;
  border-radius: var(--border-radius-small);
  display: flex;
  gap: var(--spacing-small);
  justify-content: space-between;
  padding: var(--spacing-medium);
  text-align: center;
  width: 100%;
  word-wrap: normal;

  @media (hover: hover) {
    &:hover {
      background-color: var(--color-background-selected);
    }
  }
`;

export const KpiSingle = styled.div`
  flex-direction: column;
  ${kpiSingleStyles};
`;

export const KpiSingleLink = styled(Link)`
  ${kpiSingleStyles}
  -webkit-tap-highlight-color: transparent;
  text-align: start;
  text-wrap: balance;
`;

export const Number = styled.span`
  color: var(--color-primary-light);
  font-size: calc(2 * var(--font-lg));
  font-weight: var(--font-bold);
  margin-left: var(--spacing-large);

  @media ${devices.tabletS} {
    margin-left: 0;
  }
`;
