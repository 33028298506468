/**
 * @file contains helper functions for Feed component
 */

/**
 * Whether useClickOutside hook should be executed
 *
 * @param isMobile                      Is app running on mobile device
 * @param isTablet                      Is app running in tablet view
 * @param isTopicMissing                Is current topic missing
 * @param shouldShowRightSidebarDesktop Should right sidebar be shown on desktop
 * @returns                             Whether useClickOutside should execute
 */
export const shouldStopClickOutsideExec = (
  isMobile: boolean,
  isTablet: boolean,
  isTopicMissing: boolean,
  shouldShowRightSidebarDesktop: boolean,
): boolean => {
  return (
    shouldShowRightSidebarDesktop === false ||
    !isTablet ||
    isTopicMissing ||
    isMobile
  );
};
