import { Stack, StackProps, styled } from '@mui/material';

import { CustomHashLinkProps } from '../CustomHashLink';

export const Wrapper = styled(Stack)<StackProps & CustomHashLinkProps>({
  '&:focus-visible, &:hover': {
    // No specific reason for 75% and 4, it was just a matter of trying it out
    filter: 'opacity(75%) saturate(4)',
  },
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: 'var(--spacing-tiny)',
  // Fix for Safari, where the hover effect
  // has color overshoot and jump back and emojis move a few px
  transform: 'translateZ(0)',
  transition: 'filter var(--preference-transition-duration-normal)',
});
