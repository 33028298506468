/**
 * @file contains reducers for native app related actions
 */

import {
  NATIVE_APP__DEVICE_INFO__SET,
  NATIVE_APP__DOWNLOAD_INFO__SET,
  NATIVE_APP__FIREBASE_INFO__SET,
} from '../../constants/actionTypes';
import {
  NativeAppAction,
  NativeAppActionDeviceInfoSet,
  NativeAppActionDownloadInfoSet,
  NativeAppActionFirebaseInfoSet,
  NativeAppState,
} from '../../models/nativeApp';
import initialState from '../initialState';

type Reducer = (
  state: NativeAppState,
  action: NativeAppAction,
) => NativeAppState;

/**
 * A request was made to set device info, so we store it
 *
 * @param state  The current app state
 * @param action The action that took place
 * @returns      The updated state
 */
const onNativeAppDeviceInfoSet = (
  state: NativeAppState,
  action: NativeAppActionDeviceInfoSet,
) => {
  return { ...state, deviceInfo: action.payload };
};

/**
 * A request was made to set download info, so we store it
 *
 * @param state  The current app state
 * @param action The action that took place
 * @returns      The updated state
 */
const onNativeAppDownloadInfoSet = (
  state: NativeAppState,
  action: NativeAppActionDownloadInfoSet,
) => {
  return { ...state, attachemntAction: action.payload };
};

/**
 * A request was made to set firebase info, so we store it
 *
 * @param state  The current app state
 * @param action The action that took place
 * @returns      The updated state
 */
const onNativeAppFirebaseInfoSet = (
  state: NativeAppState,
  action: NativeAppActionFirebaseInfoSet,
) => {
  return { ...state, firebaseInfo: action.payload };
};

const mapping = new Map<string, Reducer>([
  [NATIVE_APP__DOWNLOAD_INFO__SET, onNativeAppDownloadInfoSet],
  [NATIVE_APP__DEVICE_INFO__SET, onNativeAppDeviceInfoSet],
  [NATIVE_APP__FIREBASE_INFO__SET, onNativeAppFirebaseInfoSet],
]);

/**
 * Reducer for all native app actions
 *
 * @param state  The current app state
 * @param action The action that took place
 * @returns      Appropriately modified state
 */
const nativeApp = (
  state = initialState.nativeApp,
  action: NativeAppAction,
): NativeAppState => {
  const reducer = mapping.get(action.type);
  return reducer ? reducer(state, action) : state;
};

export default nativeApp;
