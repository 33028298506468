import { useTheme } from '@mui/material';
import React, { forwardRef, ForwardRefRenderFunction } from 'react';
import { HashLink, HashLinkProps } from 'react-router-hash-link';

import useDebouncedCallback from '../../../hooks/useDebouncedCallback';

export type CustomHashLinkProps = HashLinkProps;

/**
 * Custom hash link for debounced scrolling
 *
 * @param props Props for the hash link
 * @param ref   Reference to the anchor element
 * @returns     The HashLink with custom scroll behavior
 */
const CustomHashLink: ForwardRefRenderFunction<
  HTMLAnchorElement,
  CustomHashLinkProps
> = (props, ref) => {
  const theme = useTheme();

  const scrollIntoView = useDebouncedCallback((element: HTMLElement) => {
    if (element?.scrollIntoView === undefined) {
      return;
    }

    element.scrollIntoView({
      behavior: 'smooth',
    });
    // Same value used in css translations as --preference-transition-duration-normal
  }, theme.transitions.duration.standard);

  return (
    <HashLink {...props} ref={ref} scroll={scrollIntoView} smooth={true} />
  );
};

export default forwardRef(CustomHashLink);
