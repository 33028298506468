import React, {
  ChangeEvent,
  FC,
  FormEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { SEARCH__INPUT_PATTERN } from '../../../constants/preferences';

import useParamHotelId from '../../../hooks/router/params/useParamHotelId';
import useParamSearchTerm from '../../../hooks/router/params/useParamSearchTerm';
import useActiveSidebarTab from '../../../hooks/router/search/useActiveSidebarTab';
import translate from '../../../i18n/translate';
import { getSearchTextUrl } from '../../../routes/urls/search';
import SearchField from '../../MUI/SearchField';

import * as Styled from './styled';

/**
 * Search form that starts text search on the desktop
 *
 * @returns Search desktop form
 */
const SearchForm: FC = () => {
  const activeSidebar = useActiveSidebarTab();
  const hotelId = useParamHotelId();
  const inputRef = useRef<HTMLInputElement>(null);
  const location = useLocation();
  const navigate = useNavigate();
  // Doesn't just use the URL value directly
  // because we have a timeout to update the URL on key type
  const [searchTerm, setSearchTerm] = useState(useParamSearchTerm() ?? '');

  // Focus input every time the user get's back to the search page from the navigation
  useEffect(() => {
    if (activeSidebar === 'search') {
      inputRef.current?.focus();
    }
  }, [activeSidebar]);

  /**
   * When input is changed, update local state,
   * and if we're on desktop, start a timer to automatically submit the form
   * in 1s, if the user doesn't type anything else
   *
   * @param event The event triggered on input element
   */
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.currentTarget.value);
  };

  /**
   * The user has submitted the form, so we update the url
   *
   * @param event The event that took place
   */
  const handleSubmit: FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault();

    // Navigate to 1st page in search results
    const page = 1;
    navigate(getSearchTextUrl(hotelId, searchTerm, page, location.search));
  };

  return (
    <Styled.Form onSubmit={handleSubmit} role="search">
      <SearchField
        inputProps={{
          pattern: SEARCH__INPUT_PATTERN,
        }}
        inputRef={inputRef}
        onChange={onChange}
        placeholder={translate('TOPIC__SEARCH__PLACEHOLDER')}
        required={true}
        value={searchTerm}
      />
    </Styled.Form>
  );
};

export default SearchForm;
