/* eslint-disable complexity */
import React, { FC, useCallback } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import HeaderMobile from '../../../../components/Header/BaseHeaderMobile';

import AppLayout from '../../../../components/layouts/AppLayout';
import ComposeContainer from '../../../../containers/compose';
import FeedKanbanHeader from '../../../../containers/header/feedKanban';
import MessagesContainer from '../../../../containers/messages';
import SearchResultsContainer from '../../../../containers/searchResults';
import useQuickFilters from '../../../../hooks/elasticSearch/useQuickFilters';
import useParamHotelId from '../../../../hooks/router/params/useParamHotelId';
import useFeedScrollState from '../../../../hooks/store/useFeedScrollState';
import useIsMobile from '../../../../hooks/useIsMobile';
import useIsTablet from '../../../../hooks/useIsTablet';
import translate from '../../../../i18n/translate';
import { getTopicUrl } from '../../../urls/topic';

import FeedFooter from './FeedFooter';
import FeedRightSidebar from './FeedRightSidebar';
import FeedTopicMissing from './FeedTopicMissing';
import LatestPostButton from './LatestPostButton';
import { shouldStopClickOutsideExec } from './helpers';
import useFeedParams from './hooks/useFeedParams';
import useScrollFeed from './hooks/useScrollFeed';

export type Props = {
  areTopicsEmpty: boolean;
  className?: string;
};

/**
 * Main PageFeed component for the app
 *
 * @param props                Props passed to the component
 * @param props.areTopicsEmpty If there are any topics loaded or not
 * @param props.className      styled-components generated class name, needed for styling
 * @returns                    The component itself
 */
const PageFeed: FC<Props> = ({ areTopicsEmpty, className }) => {
  const hotelId = useParamHotelId();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const location = useLocation();
  const navigate = useNavigate();
  const esQuery = useQuickFilters();

  const {
    isFeed,
    shouldShowCompose,
    shouldShowFeed,
    shouldShowRightSidebarDesktop,
    shouldShowRightSidebarMobile,
    topicId,
  } = useFeedParams();

  const isTopicMissing = isFeed;

  const scrollRef = useScrollFeed(topicId);
  const { topicHasLatestPost } = useFeedScrollState(topicId);
  const clickOutsideStopExec = shouldStopClickOutsideExec(
    isMobile,
    isTablet,
    isTopicMissing,
    shouldShowRightSidebarDesktop,
  );

  const requestRightSidebarClose = useCallback(() => {
    navigate(getTopicUrl(hotelId, location, topicId));
  }, [navigate, hotelId, location, topicId]);

  /**
   * We are on feed but there is no topic selected
   */
  if (isTopicMissing) {
    return (
      <AppLayout
        className={className}
        footer={<FeedFooter />}
        header={
          isMobile && (
            <HeaderMobile backUrl={true} title={translate('GENERAL__FEED')} />
          )
        }
      >
        <FeedTopicMissing shouldShowHomeButton={areTopicsEmpty === false} />
      </AppLayout>
    );
  }

  /**
   * We are on feed and topic is selected
   */
  return (
    <AppLayout
      className={className}
      // When rendering filter results use normal direction and when rendering messages use reversed
      direction={esQuery ? 'normal' : 'reversed'}
      footer={<FeedFooter />}
      header={<FeedKanbanHeader />}
      mainAbsoluteElements={
        topicHasLatestPost && topicId !== null ? (
          <LatestPostButton topicId={topicId} />
        ) : null
      }
      mainRef={scrollRef}
      rightSidebar={{
        clickOutsideOptions: {
          onClick: requestRightSidebarClose,
          stopExec: clickOutsideStopExec,
        },
        component: <FeedRightSidebar />,
        shouldShowDesktop: shouldShowRightSidebarDesktop,
        shouldShowMobile: shouldShowRightSidebarMobile,
      }}
    >
      {shouldShowFeed && (
        <>
          {esQuery ? (
            <SearchResultsContainer esQuery={esQuery} />
          ) : (
            <MessagesContainer />
          )}
        </>
      )}
      {shouldShowCompose && <ComposeContainer nodeType="MESSAGE" />}
    </AppLayout>
  );
};

export default PageFeed;
