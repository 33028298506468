import { Stack, styled } from '@mui/material';

import breakpoints from '../../constants/breakpoints';

export const Wrapper = styled(Stack)(({ theme }) => ({
  '&:not(:has([data-is-dragging="true"]))': {
    scrollSnapType: 'x mandatory',
  },
  alignItems: 'flex-start',
  flexDirection: 'row',
  gap: 'var(--spacing-tiny)',
  height: '100%',
  overflowX: 'auto',
  padding: '0 var(--spacing-small)',
  scrollPadding: 'var(--spacing-tiny)',
  [theme.breakpoints.up(breakpoints.tabletS)]: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    overflowX: 'hidden',
    padding: 0,
    scrollPadding: 0,
    scrollSnapType: 'none',
  },
}));
