/**
 * @file Track attachment events
 */

import { Message, Topic } from '../../generated/graphql';

import { AttachmentActionType } from '../../models/attachment';

import {
  getAttachmentExtension,
  getFileName,
} from '../../utils/attachments/filenameHelpers';
import { getHotelIdFromUrl } from '../../utils/hotel';
import { getUserCurrent } from '../../utils/permissions/storage';

import { getCustomDimensions } from './customDimensions';
import { getMessageOrigin } from './helpers';

import { track, TRACK_TYPES } from '.';

type AdditionalConfig = {
  /**
   * download | upload
   */
  actionType: AttachmentActionType;
  /**
   * Name of the file (extension included)
   */
  filename: string;
  /**
   * Full Message
   */
  message: Pick<
    Message,
    'creatorType' | 'dueDate' | 'id' | 'title' | 'type'
  > & {
    topic: Pick<Topic, 'id'>;
  };
};

/**
 * Event names based on event action
 */
const eventNamesMap: Record<AttachmentActionType, string> = {
  download: 'Attachment is downloaded',
  preview: 'Attachment is previewed',
};

/**
 * Track when the user previews or downloads an attachment
 *
 * @param config          Object with details about the attachment action
 * @param config.fileType The type of the file
 * @param config.message  Message the attachment belongs to
 */
export const trackAttachmentAction = (config: AdditionalConfig): void => {
  const user = getUserCurrent();
  const hotelId = getHotelIdFromUrl();

  const { actionType, filename, message } = config;

  const messageOrigin = getMessageOrigin(message);

  const customDimensions = getCustomDimensions(
    window.location.href,
    user,
    hotelId,
    {
      entityId: getFileName(filename),
      entityType: 'file type',
      entityValue: getAttachmentExtension(filename),
      messageId: message.id,
      messageOrigin,
      messageTitle: message.title,
      task: Boolean(message.dueDate),
      topicId: message.topic.id,
    },
  );

  track(
    [
      TRACK_TYPES.TRACK_EVENT,
      'Attachments',
      actionType,
      eventNamesMap[actionType],
    ],
    customDimensions,
  );
};
