/**
 * @file contains functions used to create urls to reroute via <Link /> or navigate
 * Can be same with the path routes, but we keep urls to be future-proof
 */

import { Location } from 'react-router-dom';

import { SEARCH_PARAM__RECURRING_MODE } from '../../../constants/routing/searchParams';
import { RecurringModeParam } from '../../../models/pageParams';

import {
  removeParamsKeysFromUrl,
  replaceOrAddParamsToUrl,
} from '../../helpers/router';

/**
 * Gets url that adds `?recurringMode={section}` search param
 *
 * @param location    Location object from the history
 * @param sectionName Name of the section
 * @returns           A string in a url format
 */
export const getOpenRecurringSectionUrl = (
  location: Location,
  sectionName: RecurringModeParam,
): string => {
  return replaceOrAddParamsToUrl(location, {
    [SEARCH_PARAM__RECURRING_MODE]: sectionName,
  });
};

/**
 * Gets url that removes `?recurringMode={section}` search param
 *
 * @param location Location object from the history
 * @returns        A string in a url format
 */
export const getCloseRecurringSectionUrl = (location: Location): string => {
  return removeParamsKeysFromUrl(location, SEARCH_PARAM__RECURRING_MODE);
};
