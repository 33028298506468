import { Stack, StackProps, styled } from '@mui/material';

import { colorPrimary } from '../../../styles/mui/colors';
import { CustomHashLinkProps } from '../CustomHashLink';

export const Wrapper = styled(Stack)<StackProps & CustomHashLinkProps>({
  '& .MuiChip-root': {
    backgroundColor: colorPrimary[200],
  },
  '&:focus-visible .MuiChip-root': {
    backgroundColor: colorPrimary[400],
  },
  '&:hover .MuiChip-root': {
    backgroundColor: colorPrimary[300],
  },
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: 'var(--spacing-tiny)',
});
