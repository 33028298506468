/**
 * @file Helper functions for useEsTextQuery hook
 */

import { Operation } from 'urql';

import { PREFERENCE__SEARCH_RESULTS__LIMIT } from '../../../constants/pagination';
import {
  MessageRankedSearchQuery,
  SearchParams,
} from '../../../generated/graphql';

export type Data = MessageRankedSearchQuery | undefined;
type QueryOperation =
  | Operation<
      MessageRankedSearchQuery,
      {
        params: SearchParams;
      }
    >
  | undefined;

/**
 * Get whether there's more data to be fetched
 *
 * @param data The data retrieved from the server
 * @returns    Whether there's more data
 */
export const getHasMore = (data: Data): boolean => {
  if (data === undefined) {
    return false;
  }

  const { nodes, searchInfo } = data.messageRankedSearch;
  const nrFetched = nodes.length;
  const nrTotal = searchInfo.total;

  return nrFetched !== nrTotal;
};

/**
 * We need to check the current operation because of some race condition issues
 * with urql. Even though the variables (search term) did update, the query
 * still persists the data of the previous operation. And that's breaking our pagination logic.
 *
 * @param operation  Query operation
 * @param searchTerm The current search term
 * @returns          Whether the operation relates to the current search
 */
export const getIsCurrentOperation = (
  operation: QueryOperation,
  searchTerm: string | null,
) => {
  return operation?.variables?.params?.queryString === searchTerm;
};

/**
 * Get whether there's no data for this search term
 *
 * @param data     The data retrieved from the server
 * @param fetching Whether data is currently being fetched
 * @returns        Whether there's no data
 */
export const getIsEmpty = (data: Data, fetching: boolean): boolean => {
  if (data === undefined || fetching === true) {
    return false;
  }

  return data.messageRankedSearch.searchInfo.total === 0;
};

/**
 * Get whether the search query is invalid
 *
 * @param params The URL params for the search query
 * @param term   The term typed by the user
 * @returns      Whether the search query is invalid
 */
export const getIsValidQuery = (
  params: SearchParams | null,
  term: string | null,
): boolean => {
  return params !== null && term !== null && term.trim().length !== 0;
};

/**
 * Get the load more callback to fetch more messages if there is more to fetch.
 *
 * @param fetching Whether the data is fetching
 * @param hasMore  Whether there is more data to fetch
 * @param callback Callback that handles loading more results
 * @returns        Null if there is nothing more to fetch, or the callback if there is.
 */
export const getLoadMore = (
  fetching: boolean,
  hasMore: boolean,
  callback: () => void,
) => {
  if (hasMore && fetching === false) {
    return callback;
  }

  return null;
};

/**
 * Get the number of pages already fetched
 *
 * @param data The data fetched
 * @returns    The current page
 */
export const getPage = (data: Data): number | null => {
  if (data === undefined) {
    return null;
  }

  const nrFetched = data.messageRankedSearch.nodes.length;
  return Math.max(1, Math.ceil(nrFetched / PREFERENCE__SEARCH_RESULTS__LIMIT));
};

/**
 * Get whether we need to update the URL to reflect the fetched data
 * Data can be ahead of URL in case the (multi page) results are cached
 *
 * @param data    The data fetched
 * @param pageUrl The page in the url
 * @returns       The page to redirect to
 */
export const getPageDataSync = (
  data: Data,
  pageUrl: number | null,
): number | null => {
  const pageData = getPage(data);

  if (pageUrl === null || pageData === null || pageUrl >= pageData) {
    return null;
  }

  return pageData;
};
