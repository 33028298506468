import React, { FC } from 'react';

import { HotelPermissions } from '../../../generated/graphql';
import useParamHotelId from '../../../hooks/router/params/useParamHotelId';

import useLogout from '../../../hooks/useLogout';
import translate from '../../../i18n/translate';
import { getDevPanelUrl } from '../../../routes/urls/devPanel';
import { getSelectHotelUrl } from '../../../routes/urls/more';
import { getSettingsUrl } from '../../../routes/urls/settings';
import MobileFullViewNavigation from '../../MUI/MobileFullViewNavigation';

import { NavigationListButtonProps } from '../../MUI/MobileFullViewNavigation/NavigationListButton';

import { generateLinks } from './links';

export type Props = {
  hotelPermissions: HotelPermissions[];
};

/**
 * Profile menu for the mobile screens
 *
 * @param props                  Props passed to the component
 * @param props.hotelPermissions Current user hotels list
 * @returns                      MobileNavigation component
 */
const MoreNavigationMobile: FC<Props> = ({ hotelPermissions }) => {
  const hotelId = useParamHotelId();
  const logout = useLogout();
  const numberOfHotels = hotelPermissions.length;

  const linksConfig = {
    devPanelUrl: getDevPanelUrl(hotelId),
    selectHotelUrl: numberOfHotels <= 1 ? '' : getSelectHotelUrl(hotelId),
    settingsUrl: getSettingsUrl(hotelId),
  };

  const links = generateLinks(linksConfig);

  const buttonLogout: NavigationListButtonProps = {
    icon: 'logout',
    isDisabled: false,
    label: translate('GENERAL__LOGOUT'),
    onClick: logout,
  };

  return <MobileFullViewNavigation buttons={[buttonLogout]} links={links} />;
};

export default MoreNavigationMobile;
