/**
 * @file Contains a hook for managing WisePope integration
 */
import { useEffect, useMemo, useState } from 'react';

import {
  initWisePops,
  setWisePopsCustomProperties,
} from '../../utils/services/wisePops';

import { getWisePopsCustomProperties } from './helpers';

/**
 * Hook for initializing WisePops and setting custom properties
 */
const useWisePops = () => {
  const [isInitialized, setIsInitialized] = useState(false);
  const customProperties = useMemo(() => getWisePopsCustomProperties(), []);

  useEffect(() => {
    if (!isInitialized) {
      initWisePops();
      setIsInitialized(true);
    } else {
      setWisePopsCustomProperties(customProperties);
    }
  }, [customProperties, isInitialized]);
};

export default useWisePops;
