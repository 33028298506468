import React, { FC } from 'react';

import { useLocation } from 'react-router-dom';

import BaseHeaderMobile from '../../../../components/Header/BaseHeaderMobile';
import useLayoutConfig from '../../../../components/layouts/SettingsLayout/hooks/useLayoutConfig';
import useParamHotelId from '../../../../hooks/router/params/useParamHotelId';
import useParamRecurringFrequency from '../../../../hooks/router/params/useParamRecurringFrequency';
import useParamTemplateId from '../../../../hooks/router/params/useParamTemplateId';
import useIsComposeOpen from '../../../../hooks/router/search/useIsComposeOpen';
import useRecurringMode from '../../../../hooks/router/search/useRecurringMode';
import useCurrentPath from '../../../../hooks/useCurrentPath';
import { getCloseRecurringSectionUrl } from '../../../../routes/urls/searchParams/recurring';
import ComposeHeader from '../../compose';

import { getSettingsSegmentedButtons } from '../helpers';

import { getContentRight, getSettingsBackUrl } from './helpers';

/**
 * Mobile header component for the settings page
 *
 * @returns The component itself
 */
const SettingsHeaderMobile: FC = () => {
  const currentPath = useCurrentPath();
  const hotelId = useParamHotelId();
  const isComposeOpen = useIsComposeOpen();
  // Use link without sidebar params for mobile by passing true for isTablet
  const isTablet = true;
  const location = useLocation();
  const recurringFrequency = useParamRecurringFrequency();
  const recurringMode = useRecurringMode();
  const templateId = useParamTemplateId();

  const { buttons, title } = useLayoutConfig();

  if (isComposeOpen) {
    return <ComposeHeader nodeId={templateId} nodeType="TEMPLATE" />;
  }

  /**
   * Creates the back url for settings header
   *
   * @returns The back url
   *
   * @summary If recurring mode is open, return the close url because the screen
   * is shown based on url params. Otherwise, return the settings back url
   */
  const getBackUrl = () => {
    if (recurringMode === null) {
      return getSettingsBackUrl(currentPath, hotelId, isTablet);
    }
    return getCloseRecurringSectionUrl(location);
  };

  const contentRight = getContentRight(currentPath, buttons[0]);
  const segmentedButtons = getSettingsSegmentedButtons({
    currentPath,
    hotelId,
    isTablet,
    recurringFrequency,
    templateId,
  });

  return (
    <BaseHeaderMobile
      backUrl={getBackUrl()}
      contentRight={contentRight}
      segmentedButtons={segmentedButtons}
      title={title}
    />
  );
};

export default SettingsHeaderMobile;
