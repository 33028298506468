/**
 * @file contains hook for handling native messaging
 */

import useMessageEventHandler from '../../native-messaging/hooks/useMessageEventHandler';
import { MessageEventResponse } from '../../native-messaging/models/MessageEventResponse';

import useNativeResponseHandler from './useNativeResponseHandler';

/**
 * Hook to handle native communication
 */
const useNativeCommunicationHandler = () => {
  const responseHandler = useNativeResponseHandler();

  /**
   * Handle the response from the native app
   *
   * @param response The response from the native app
   */
  const handleResponse = (response: MessageEventResponse) => {
    responseHandler[response.eventName](response.data);
  };

  useMessageEventHandler(handleResponse);
};

export default useNativeCommunicationHandler;
