/**
 * @file Contains getters for search params
 */

import { Location } from 'react-router-dom';

import { ModeLabels } from '../../constants/metaModesLabels';
import {
  LABEL_PARAMS__BY_USAGE,
  SEARCH_PARAM__ATTACHMENT_ID,
  SEARCH_PARAM__COMPOSE_MODE,
  SEARCH_PARAM__FILTER__MEMBERS,
  SEARCH_PARAM__FILTER__QUICK,
  SEARCH_PARAM__FILTER__QUICK_STATE,
  SEARCH_PARAM__GROUP_ID,
  SEARCH_PARAM__LABEL_ID,
  SEARCH_PARAM__MESSAGE_ID,
  SEARCH_PARAM__MODAL_ID,
  SEARCH_PARAM__RECURRING_MODE,
  SEARCH_PARAM__REDIRECT,
  SEARCH_PARAM__SIDEBAR,
  SEARCH_PARAM__TEMPLATE_ID,
  SEARCH_PARAM__TOPIC_ID,
  SEARCH_PARAM__USER_ID,
} from '../../constants/routing/searchParams';
import { SIDEBAR__PARAM_TOPICS } from '../../constants/sidebar';
import { Label, Template, Topic, User } from '../../generated/graphql';
import { Attachment } from '../../models/attachment';
import { GroupID } from '../../models/group';
import { LabelUsage } from '../../models/message';
import { ModalId } from '../../models/modal';
import { ComposeModeParam, RecurringModeParam } from '../../models/pageParams';
import { CustomSearchParams } from '../../models/searchParams';

import {
  modifyUrlParams,
  parseUrlString,
  removeParamsKeysFromUrl,
} from './router';

/**
 * Get the provided search param value
 *
 * @param paramKey The search param key
 * @param location History Location object (if not provided, we get search from window.location)
 * @returns        The modalId search param
 */
export const getSearchParam = <T extends keyof CustomSearchParams>(
  paramKey: T,
  location?: Location,
): CustomSearchParams[T] | null => {
  const search = location?.search ?? window.location?.search;
  const params = new URLSearchParams(search);
  const value = params.get(paramKey);

  if (value === null) {
    return null;
  }

  return decodeURIComponent(value) as CustomSearchParams[T];
};

/**
 * Get attachmentId search param from the url
 *
 * @param location Location object from the history
 * @returns        Attachment id or null
 */
export const getSearchParamAttachmentId = (
  location: Location,
): Attachment['id'] | null =>
  getSearchParam(SEARCH_PARAM__ATTACHMENT_ID, location);

/**
 * Get composeMode search param from the url
 *
 * @param location Location object from the history
 * @returns        Compose mode or null
 */
export const getSearchParamComposeMode = (
  location: Location,
): ComposeModeParam | null =>
  getSearchParam(SEARCH_PARAM__COMPOSE_MODE, location);

/**
 * Get filterMembers search param from the url
 *
 * @param location Location object from the history
 * @returns        Set of ids or null
 */
export const getSearchParamFilterMembers = (
  location: Location,
): Set<string> | null => {
  const filterMembers = getSearchParam(SEARCH_PARAM__FILTER__MEMBERS, location);
  if (filterMembers === null) {
    return null;
  }

  return new Set(filterMembers.split(','));
};

/**
 * Get quickFilters search param from the url
 *
 * @param location Location object from the history
 * @returns        Quick filter id or null
 */
export const getSearchParamFilterQuick = (
  location: Location,
): string | null => {
  const quickFilter = getSearchParam(SEARCH_PARAM__FILTER__QUICK, location);

  return quickFilter;
};

/**
 * Get quickFiltersState search param from the url
 *
 * @param location Location object from the history
 * @returns        open or null
 */
export const getSearchParamFilterQuickState = (
  location: Location,
): string | null => getSearchParam(SEARCH_PARAM__FILTER__QUICK_STATE, location);

/**
 * Get groupId search param from the url
 *
 * @param location Location object from the history
 * @returns        Group id or null
 */
export const getSearchParamGroupId = (location: Location): GroupID | null =>
  getSearchParam(SEARCH_PARAM__GROUP_ID, location);

/**
 * Get labelId search param from the url
 *
 * @param location Location object from the history
 * @returns        Label id or null
 */
export const getSearchParamLabelId = (location: Location): Label['id'] | null =>
  getSearchParam(SEARCH_PARAM__LABEL_ID, location);

/**
 * Get label mode search param from the url
 *
 * @param location Location object from the history
 * @param where    Where the label mode is used
 * @returns        Label id or null
 */
export const getSearchParamLabelMode = (
  location: Location,
  where: LabelUsage,
): ModeLabels | null => {
  return getSearchParam(LABEL_PARAMS__BY_USAGE[where], location);
};

/**
 * Get messageId search param from the url
 *
 * @param location Location object from the history
 * @returns        Message id or null
 */
export const getSearchParamMessageId = (location: Location): GroupID | null =>
  getSearchParam(SEARCH_PARAM__MESSAGE_ID, location);

/**
 * Get modal id search param from the url
 *
 * @param location Location object from the history
 * @returns        ModalId or null
 */
export const getSearchParamModalId = (location: Location): ModalId | null =>
  getSearchParam(SEARCH_PARAM__MODAL_ID, location);

/**
 * Get redirect search param from the url
 *
 * @param location Location object from the history
 * @returns        Redirect param value or null
 */
export const getSearchParamRedirect = (location: Location) =>
  getSearchParam(SEARCH_PARAM__REDIRECT, location);

/**
 * Get templateId search param from the url
 *
 * @param location Location object from the history
 * @returns        Template id or null
 */
export const getSearchParamTemplateId = (
  location: Location,
): Template['id'] | null => getSearchParam(SEARCH_PARAM__TEMPLATE_ID, location);

/**
 * Get topicId search param from the url
 *
 * @param location Location object from the history
 * @returns        Topic id or null
 */
export const getSearchParamTopicId = (location: Location): Topic['id'] | null =>
  getSearchParam(SEARCH_PARAM__TOPIC_ID, location);

/**
 * Get UserId search param from the url
 *
 * @param location Location object from the history
 * @returns        User id or null
 */
export const getSearchParamUserId = (location: Location): User['id'] | null =>
  getSearchParam(SEARCH_PARAM__USER_ID, location);

/**
 * Get recurringMode search param from the url
 *
 * @param location Location object from the history
 * @returns        recurringMode
 */
export const getSearchParamRecurringMode = (
  location: Location,
): RecurringModeParam | null =>
  getSearchParam(SEARCH_PARAM__RECURRING_MODE, location);

/**
 * Get sidebar search param from the url
 *
 * @param location Location object from the history
 * @returns        sidebar param
 */
export const getSearchParamSidebar = (location: Location): string | null =>
  getSearchParam(SEARCH_PARAM__SIDEBAR, location);

/**
 * Get the search param for the topic details
 *
 * @param isTablet Whether the device is a tablet
 * @param location Location object from the history
 * @returns        Search param with topics selected in sidebar
 */
export const getTopicDetailsSearchParam = (
  isTablet: boolean,
  location: Location,
): string => {
  // Return the same search params if device is not a tablet
  if (!isTablet) {
    return location.search;
  }

  // Remove sidebar param from the url to close the sidebar on tablet
  const [, search] = parseUrlString(
    removeParamsKeysFromUrl(location, SEARCH_PARAM__SIDEBAR),
  );

  return search;
};

/**
 * Get the search param for the topics sidebar
 *
 * @param isTablet Whether the device is a tablet
 * @param location Location object from the history
 * @returns        Search param with topics selected in sidebar
 */
export const getTopicsSidebarSearchParam = (
  isTablet: boolean,
  location: Location | null,
): string => {
  if (location === null) {
    return '';
  }

  // For tablet device we want to have sidebar closed
  const [, search] = parseUrlString(
    modifyUrlParams(location, {
      params: isTablet
        ? {}
        : { [SEARCH_PARAM__SIDEBAR]: SIDEBAR__PARAM_TOPICS },
      paramsToRemove: isTablet ? [SEARCH_PARAM__SIDEBAR] : [],
    }),
  );

  return search;
};
