/**
 * @file Hook that handles message events sent from the native app
 */
import { useCallback, useEffect } from 'react';

import messageEventHandler from '../helpers/messageEventHandler';
import { MessageEventResponse } from '../models/MessageEventResponse';

/**
 * Handle message events sent from the native app
 *
 * @param handleResponse The function to handle the response from the native app
 */
const useMessageEventHandler = (
  handleResponse: (response: MessageEventResponse) => void,
) => {
  /**
   * A message has come from the native wrapped
   *
   * @param event The event that took place
   */
  const handleMessageEvent = useCallback(
    (event: MessageEvent<string>) => {
      messageEventHandler(event, handleResponse);
    },
    [handleResponse],
  );

  useEffect(() => {
    if (window.ReactNativeWebView === undefined) {
      return;
    }

    /**
     * Add event listeners for messages from the native app
     * We need to add two listeners because the event can be dispatched on either the document or the window
     * depending on the platform (document for iOS, window for Android)
     */
    document.addEventListener('message', handleMessageEvent, false);
    window.addEventListener('message', handleMessageEvent, false);

    return () => {
      document.removeEventListener('message', handleMessageEvent);
      window.removeEventListener('message', handleMessageEvent);
    };
  }, [handleMessageEvent]);
};

export default useMessageEventHandler;
