import React, { FC } from 'react';

import { CombinedError } from 'urql';

import { ILLUSTRATION__SEARCH } from '../../../../constants/illustrations';
import {
  TemplateBasicFragment,
  TopicBasicFragment,
} from '../../../../generated/graphql';

import useIsMobile from '../../../../hooks/useIsMobile';
import EmptyState from '../../../Common/EmptyState';
import InputSearch from '../../../Common/InputSearch';

import List from './List';

import {
  DataType,
  getIsEmpty,
  getIsSearching,
  getShouldShowNoResults,
  getShouldShowSearchBox,
  searchDataByTitle,
} from './helpers';

export type Props<T> = {
  actionLink?: {
    href: string;
    text: string;
  } | null;
  dataAll: T[];
  dataCurrent: string | null;
  dataSearchValue: string;
  dataType: DataType;
  error?: CombinedError;
  inputSearchLabel: string;
  inputSearchPlaceholder: HTMLInputElement['placeholder'];
  isLoading: boolean;
  noResultsText: string;
  onSearch: (term: string) => void;
  requestChange: (id: string) => void;
};

/**
 * ListSearch panel,
 * Consists of a filter box and topics list
 *
 * @param props                        Props passed to the component
 * @param props.actionLink             Action button used in empty state component
 * @param props.dataAll                All topics
 * @param props.dataCurrent            Currently selected topic
 * @param props.dataSearchValue        <input type="search" /> value attribute
 * @param props.dataType               Type of data rendered inside of list
 * @param props.error                  Topics fetching error
 * @param props.inputSearchLabel       Label for search input
 * @param props.inputSearchPlaceholder Placeholder for search input
 * @param props.isLoading              Whether the data is being fetched
 * @param props.noResultsText          Text used when there is no search results
 * @param props.onSearch               Callback to invoke when search term is changed
 * @param props.requestChange          Request to change currently selected topic
 * @returns                            The component itself
 */
const ListSearch: FC<Props<TemplateBasicFragment | TopicBasicFragment>> = ({
  actionLink = null,
  dataAll,
  dataCurrent,
  dataSearchValue,
  dataType,
  error,
  inputSearchLabel,
  inputSearchPlaceholder,
  isLoading,
  noResultsText,
  onSearch,
  requestChange,
}) => {
  const isMobile = useIsMobile();

  const dataVisible = searchDataByTitle(dataAll, dataSearchValue);

  const isEmpty = getIsEmpty(dataVisible);
  const isSearching = getIsSearching(dataSearchValue);

  const shouldShowSearchbox = getShouldShowSearchBox(isEmpty, isSearching);
  const shouldShowNoResults = getShouldShowNoResults(
    isEmpty,
    isLoading,
    isSearching,
  );

  if (shouldShowNoResults) {
    return (
      <EmptyState
        actionLink={actionLink}
        illustrationName={isMobile ? ILLUSTRATION__SEARCH : null}
        subtitle={noResultsText}
        title=""
      />
    );
  }

  return (
    <div>
      {shouldShowSearchbox && (
        <InputSearch
          label={inputSearchLabel}
          onChange={onSearch}
          placeholder={inputSearchPlaceholder}
          shouldFocus={true}
          value={dataSearchValue}
        />
      )}
      <List
        dataSelected={dataCurrent}
        dataType={dataType}
        dataVisible={dataVisible}
        error={error}
        isLoading={isLoading}
        isSearching={isSearching}
        requestChange={requestChange}
      />
    </div>
  );
};

export default ListSearch;
