import { Button, styled } from '@mui/material';

import { colorTertiary } from '../../../styles/mui/colors';

export const MuiButton = styled(Button)({
  '&:active,:hover,': {
    boxShadow: 'none',
  },
  '&:focus:not(:focus-visible)': {
    backgroundColor: colorTertiary[500],
  },
  border: 'var(--mui-button-outlined-border)',
  boxShadow: 'none',
  flex: '1 1 0',
  paddingLeft: 'var(--spacing-big)',
  paddingRight: 'var(--spacing-big)',
  textTransform: 'capitalize',
});
