import { IconButton, IconButtonProps, styled } from '@mui/material';

import { LinkProps } from 'react-router-dom';

import { getDesktopHeaderStyle } from '../../../styles/mui/common';
import RichText from '../../Common/RichText';
import HeaderSegmentedButtons from '../SegmentedButtons';

export const Wrapper = styled('div')({
  ...getDesktopHeaderStyle(),
  display: 'flex',
  flexDirection: 'row',
  gap: 'var(--spacing-medium)',
  marginTop: 'var(--spacing-small)',
  position: 'relative',
});

export const HeaderContent = styled('div')({
  display: 'grid',
  gap: 'var(--spacing-medium)',
  gridTemplateColumns: '1fr auto',
  justifyContent: 'start',
  width: '100%',
});

export const HGroup = styled('hgroup')({
  display: 'flex',
  flexFlow: 'column',
  gap: 'var(--spacing-medium)',
  paddingTop: 'var(--header-desktop-hgroup-padding-top)',
  position: 'relative',
});

export const Description = styled(RichText)({
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 'var(--preference-label-line-clamp)',
  display: '-webkit-box',
  overflow: 'hidden',
  textAlign: 'start',
  textWrap: 'wrap',
});

export const ActionButtonRight = styled(IconButton)<
  IconButtonProps & LinkProps
>({
  alignSelf: 'center',
  aspectRatio: '1',
  justifySelf: 'center',
});

export const ButtonsWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: 'var(--spacing-medium)',
  gridColumn: '1',
  justifySelf: 'baseline',
});

export const SegmentedButtons = styled(HeaderSegmentedButtons)`
  grid-row: 2;
`;
