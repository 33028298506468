/**
 * @file Post event to native app to flush cookies
 */
import { REQUEST__COOKIES_FLUSH } from '../../constants';
import { postMessageToNative } from '../../helpers/postMessageToNative';

/**
 * Post event to native app to flush cookies
 */
const postCookiesFlushEvent = (): void => {
  postMessageToNative({
    eventName: REQUEST__COOKIES_FLUSH,
  });
};

export default postCookiesFlushEvent;
