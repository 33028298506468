/**
 * @file contains helpers for attachment error handling
 */
import { Dispatch } from 'redux';

import {
  FILE__EXTENSIONS,
  FILE__MAX_FILE_SIZE,
  FILE__MAX_FILES_UPLOAD,
} from '../../constants/forms';
import translate from '../../i18n/translate';
import { addToastMessage } from '../../store/actions/toastMessage';

import intlPluralFormatter from '../intlPluralFormat';

import { getAttachmentExtension } from './filenameHelpers';

export type AttachmentErrorsMapKeys = 'count' | 'format' | 'size';

/**
 * Check whether all conditions for file upload are met and if not return file name for each file
 *
 * @param fileList         List of files selected for upload
 * @param draftFilesLength Length of current draft files
 * @returns                Record with file names arrays for each error type
 */
export const getAttachmentErrors = (
  fileList: File[],
  draftFilesLength: number,
): Record<AttachmentErrorsMapKeys, string[]> | null => {
  const errors: Record<AttachmentErrorsMapKeys, string[]> = {
    count: [],
    format: [],
    size: [],
  };

  const extensionsSupported = Object.keys(FILE__EXTENSIONS);

  const filesOverMaxCount = fileList
    .slice(FILE__MAX_FILES_UPLOAD - draftFilesLength)
    .map(file => file.name);

  fileList.forEach(file => {
    const extension = getAttachmentExtension(file.name) ?? '';
    if (extensionsSupported.includes(extension) === false) {
      errors.format.push(file.name);
    }

    if (file.size > FILE__MAX_FILE_SIZE) {
      errors.size.push(file.name);
    }
  });

  if (filesOverMaxCount.length > 0) {
    errors.count.push(...filesOverMaxCount);
  }

  if (
    errors.count.length === 0 &&
    errors.format.length === 0 &&
    errors.size.length === 0
  ) {
    return null;
  }

  return errors;
};

/**
 * Get Record that contains translations for different attachment error types
 * (count, format and size errors)
 *
 * @returns Record containing single and plural translations for attachment errors
 */
export const getFileToastErrors = (): Record<
  AttachmentErrorsMapKeys,
  { textSingle: string; textPlural: string }
> => ({
  count: {
    textPlural: translate('ATTACHMENT__TOAST__PLURAL__COUNT__ERROR'),
    textSingle: translate('ATTACHMENT__TOAST__SINGLE__COUNT__ERROR'),
  },
  format: {
    textPlural: translate('ATTACHMENT__TOAST__PLURAL__FORMAT__ERROR'),
    textSingle: translate('ATTACHMENT__TOAST__SINGLE__FORMAT__ERROR'),
  },
  size: {
    textPlural: translate('ATTACHMENT__TOAST__PLURAL__SIZE_LIMIT__ERROR'),
    textSingle: translate('ATTACHMENT__TOAST__SINGLE__SIZE_LIMIT__ERROR'),
  },
});

/**
 * If there are any errors dispatch action that creates toast message
 *
 * @param dispatch Redux dispatch callback
 * @param errors   Record of errors for attachment upload
 */
export const showFileToastError = (
  dispatch: Dispatch,
  errors: Record<AttachmentErrorsMapKeys, string[]>,
) => {
  const toastErrorContent = getFileToastErrors();

  Object.entries(errors).forEach(
    ([key, errorFileNames]: [AttachmentErrorsMapKeys, string[]]) => {
      if (errorFileNames.length > 0) {
        const additionalTextPrefix =
          errorFileNames.length > 1
            ? translate('ATTACHMENT__TOAST__PLURAL__INFO')
            : translate('ATTACHMENT__TOAST__SINGLE__INFO');
        dispatch(
          addToastMessage({
            additionalText: `${additionalTextPrefix} ${intlPluralFormatter(
              errorFileNames,
            )}`,
            text:
              errorFileNames.length > 1
                ? toastErrorContent[key].textPlural
                : toastErrorContent[key].textSingle,
            title: translate('ATTACHMENT__TOAST__TITLE'),
            type: 'error',
          }),
        );
      }
    },
  );
};
