// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.roq-chat-message-bell,
.roq-chat-sidebar,
.roq-panel {
  --color-message-sent: var(--color-blue-lightest);
  --font-avatar: 0.65rem;
  --size-avatar: 36px;
  --size-bell-icon: 30px;
  --size-file-preview-thumb-height: 84px;
  --size-file-preview-thumb-width: 70px;
  --size-file-preview-width: 100px;
  --size-indicator-icon: 1rem;
  --size-input-height: 41px;
  --size-input-max-height: 100px;
  --size-input-padding-left: 56px;
  --size-message-chevron-height: 12px;
  --size-message-chevron-width: 14px;
  --size-message-skeleton: 300px;
  --size-no-messages-icon: 150px;
  --size-progress-bar: 160px;
  --spacing-input-desktop: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/css/roqChat/_variables.css"],"names":[],"mappings":"AAAA;;;EAGE,gDAAgD;EAChD,sBAAsB;EACtB,mBAAmB;EACnB,sBAAsB;EACtB,sCAAsC;EACtC,qCAAqC;EACrC,gCAAgC;EAChC,2BAA2B;EAC3B,yBAAyB;EACzB,8BAA8B;EAC9B,+BAA+B;EAC/B,mCAAmC;EACnC,kCAAkC;EAClC,8BAA8B;EAC9B,8BAA8B;EAC9B,0BAA0B;EAC1B,6BAA6B;AAC/B","sourcesContent":[".roq-chat-message-bell,\n.roq-chat-sidebar,\n.roq-panel {\n  --color-message-sent: var(--color-blue-lightest);\n  --font-avatar: 0.65rem;\n  --size-avatar: 36px;\n  --size-bell-icon: 30px;\n  --size-file-preview-thumb-height: 84px;\n  --size-file-preview-thumb-width: 70px;\n  --size-file-preview-width: 100px;\n  --size-indicator-icon: 1rem;\n  --size-input-height: 41px;\n  --size-input-max-height: 100px;\n  --size-input-padding-left: 56px;\n  --size-message-chevron-height: 12px;\n  --size-message-chevron-width: 14px;\n  --size-message-skeleton: 300px;\n  --size-no-messages-icon: 150px;\n  --size-progress-bar: 160px;\n  --spacing-input-desktop: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
