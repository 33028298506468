import React, { FC } from 'react';
import { connect } from 'react-redux';

import Editor from '../../../components/Common/Editor';
import {
  MESSAGE__MAX_TEXT_LENGTH,
  MESSAGE__TEXT_REQUIRED,
} from '../../../constants/forms';
import useAttachmentUpload from '../../../hooks/attachments/useAttachmentUpload';
import useIsMobile from '../../../hooks/useIsMobile';
import translate from '../../../i18n/translate';

import { getUsage } from './helpers';
import { mapDispatchToProps, mapStateToProps } from './redux';
import { Props } from './types';

/**
 * Container for message text
 *
 * @param props                     Props passed to the container
 * @param props.breakpointMin       Breakpoint after which is the component visible
 * @param props.nodeId              Whether Editor is in template edit mode
 * @param props.nodeType            Whether the compose form is being used for messages or templates
 * @param props.requestCancel       Request that editing is canceled
 * @param props.requestTextUpdate   Request that the text is updated in Redux
 * @param props.shouldDisableSubmit Whether to disable the submit button
 * @param props.text                Current draft text
 * @returns                         The container itself
 */
const ComposeMetaDescriptionContainer: FC<Props> = ({
  breakpointMin,
  nodeId,
  nodeType,
  requestCancel,
  requestTextUpdate,
  shouldDisableSubmit,
  text,
}) => {
  const { attachmentsFormatted, handleFileRemove, inputProps } =
    useAttachmentUpload(nodeType);
  const isMobile = useIsMobile();

  return (
    <Editor
      breakpointMin={breakpointMin}
      dataAttachments={attachmentsFormatted}
      dataInputFileProps={inputProps}
      isRequired={MESSAGE__TEXT_REQUIRED}
      maxLength={MESSAGE__MAX_TEXT_LENGTH}
      nodeId={nodeId}
      onChangeText={requestTextUpdate}
      placeholder={translate('COMPOSE__PLACEHOLDER__TEXT')}
      requestAttachmentRemove={handleFileRemove}
      requestEditCancel={requestCancel}
      shouldDisableSubmit={shouldDisableSubmit}
      usage={getUsage(isMobile, nodeType)}
      value={text}
    />
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ComposeMetaDescriptionContainer);
