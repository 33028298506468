import { Button } from '@mui/material';
import React, { FC } from 'react';

import InputFileAttachment from '../../../components/Common/InputFileAttachment';
import useAttachmentsUpload from '../../../hooks/attachments/useAttachmentUpload';
import translate from '../../../i18n/translate';
import { ComposeNodeType } from '../../compose/props';

type Props = {
  nodeType: ComposeNodeType;
};

/**
 * Content that is rendered on the right side of the header in the compose view
 *
 * @param props          Props passed to the component
 * @param props.nodeType Whether the compose form is being used for messages or templates
 * @returns              The component itself
 */
const ContentRightAttachments: FC<Props> = ({ nodeType }) => {
  const labelAction = translate('GENERAL__ADD');
  const { inputProps } = useAttachmentsUpload(nodeType);

  /**
   * Open the file dialog when the button is clicked
   */
  const onClick = () => {
    inputProps.ref.current?.click();
  };

  return (
    <>
      <Button color="secondary" onClick={onClick} variant="text">
        {labelAction}
      </Button>
      <InputFileAttachment inputFileProps={inputProps} />
    </>
  );
};

export default ContentRightAttachments;
