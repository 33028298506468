/**
 * @file contains helper functions for file upload to AWS S3
 */

import { FILE__EXTENSIONS } from '../../constants/forms';
import { File as CustomFile } from '../../generated/graphql';
import {
  AttachmentDraftPreUploadMapEntry,
  PresignedPostData,
} from '../../models/attachment';

import deviceInfo from '../device/deviceInfo';

import { getIsReactNativeWebView } from '../webview/helpers';

import { getAttachmentExtension } from './filenameHelpers';

export const fileExtensions = Object.keys(FILE__EXTENSIONS)
  .map(key => `.${key}`)
  .toString();

/**
 * Determines the acceptable file input types for attachments based on the device and environment.
 *
 * This function checks if the current environment is a React Native WebView or if the device is an Android, iPad, or iPhone.
 * If any of these conditions are true, it returns `undefined`, indicating that all file types are acceptable.
 * Otherwise, it returns a predefined set of acceptable file extensions.
 *
 * This is done to allow the native file picker to handle file selection.
 *
 * @returns A string of acceptable file extensions or `undefined` if all file types are acceptable
 */
export const getAttachmentInputAccept = (): string | undefined => {
  const isReactNativeWebView = getIsReactNativeWebView();

  return isReactNativeWebView ||
    deviceInfo.Android ||
    deviceInfo.iPad ||
    deviceInfo.iPhone
    ? undefined
    : fileExtensions;
};

/**
 * Parses an array of JSON strings into an array of `PresignedPostData` objects
 *
 * @param postFields An array of JSON strings representing `PresignedPostData`
 * @returns          An array of `PresignedPostData` objects
 */
export const parsePostFields = (postFields: string[]): PresignedPostData[] => {
  return postFields.map(
    filePostFields => JSON.parse(filePostFields) as PresignedPostData,
  );
};

/**
 * Creates a map of attachment drafts from the provided files and post fields
 *
 * @param files      An array of `File` objects to be uploaded
 * @param postFields An array of strings representing the post fields
 * @returns          A map where each key is derived from the parsed post data and each value is an object containing attachment details
 */
export const createAttachmentsFromFiles = (
  files: File[],
  postFields: string[],
): Map<CustomFile['key'], AttachmentDraftPreUploadMapEntry> => {
  const parsedPostData = parsePostFields(postFields);

  return new Map(
    files.map((file, index) => [
      parsedPostData[index].fields.key,
      {
        extension: getAttachmentExtension(file.name),
        file,
        isDraft: true,
        isUploading: true,
        name: file.name,
        postData: parsedPostData[index],
      },
    ]),
  );
};
