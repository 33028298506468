/**
 * @file Whether this is the first login (getters and setters)
 */

import cookie from 'js-cookie';

import {
  KEY__FIRST_LOGIN,
  KEY__FIRST_PAGE_VIEW,
} from '../../constants/localStorage';
import { PREFERENCE_COOKIE_OPTIONS_COMMON } from '../../constants/preferences';
import postCookiesFlushEvent from '../../native-messaging/handlers/web/cookiesFlush';

/**
 * Cookie values are stored as strings
 */
const valueTrue = true.toString();
const valueFalse = false.toString();

/**
 * Get whether this is the first time the user has logged in to Hotelboard
 *
 * @returns Whether it's true
 */
export const getIsFirstLogin = (): boolean => {
  return cookie.get(KEY__FIRST_LOGIN) === valueTrue;
};

/**
 * Store info whether this is the user's first auth page view
 */
const setIsFirstPageView = () => {
  cookie.set(KEY__FIRST_PAGE_VIEW, valueTrue, PREFERENCE_COOKIE_OPTIONS_COMMON);
};

/**
 * Store info whether this is the user's first log in to Hotelboard
 *
 * @param state Whether it's true
 */
export const setIsFirstLogin = (state: boolean) => {
  const value = state === true ? valueTrue : valueFalse;
  cookie.set(KEY__FIRST_LOGIN, value, PREFERENCE_COOKIE_OPTIONS_COMMON);
  postCookiesFlushEvent();

  if (state === true) {
    // Since we are reloading the app on login, we set this one-time cookie
    // to know whether this is the first auth page view.
    setIsFirstPageView();
  }
};

/**
 * Check whether this is the first time the user has visited an auth page
 * and remove the cookie that checks this if needed.
 *
 * @returns Whether it's true
 */
export const checkIsFirstPageView = (): boolean => {
  const isFirstView = cookie.get(KEY__FIRST_PAGE_VIEW) === valueTrue;

  if (isFirstView === true) {
    cookie.remove(KEY__FIRST_PAGE_VIEW);
  }

  return isFirstView;
};
