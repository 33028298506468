import React, { FC } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import Compose from '../../components/Compose';
import useAttachmentUpload from '../../hooks/attachments/useAttachmentUpload';
import useParamTemplateId from '../../hooks/router/params/useParamTemplateId';
import useIsComposeOpen from '../../hooks/router/search/useIsComposeOpen';
import {
  getCloseComposeUrl,
  getOpenComposeUrl,
} from '../../routes/urls/searchParams/compose';

import { reportApiErrors } from '../../utils/error';

import useComposeData from './hooks/useComposeData';
import useRedirectPath from './hooks/useRedirectPath';
import useSubmitData from './hooks/useSubmitData';
import mapDispatchToProps from './mapDispatchToProps';
import mapStateToProps from './mapStateToProps';
import { Props } from './props';

/**
 * Container for the compose
 *
 * @param props                     Props passed to the component
 * @param props.dataDraft           Draft data
 * @param props.nodeType            Whether the compose form is being used for messages or templates
 * @param props.requestDraftClear   Request that the draft is cleared
 * @param props.shouldDisableSubmit Whether to disable form submission
 * @returns                         The component itself
 */
const ComposeContainer: FC<Props> = ({
  dataDraft,
  nodeType,
  requestDraftClear,
  shouldDisableSubmit,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const nodeId = useParamTemplateId();
  const redirectPath = useRedirectPath(nodeId);
  const shouldShow = useIsComposeOpen();

  const { attachmentsFormatted, handleClearDraft } =
    useAttachmentUpload(nodeType);
  const existingChecklistIds = useComposeData(nodeId, nodeType);

  const { submitData, fetching = false } =
    useSubmitData(
      attachmentsFormatted,
      dataDraft,
      existingChecklistIds,
      nodeId,
      nodeType,
    ) ?? {};

  /**
   * Request that the compose form state is set
   *
   * @param state The compose state (true -> show; false -> hide)
   */
  const requestStateSet = (state: boolean): void => {
    const getUrl = state ? getOpenComposeUrl : getCloseComposeUrl;
    navigate(getUrl(location));
  };

  /**
   * Request cancel editing the template
   */
  const requestCancel = () => {
    if (redirectPath !== null) {
      navigate(redirectPath);
    }

    handleClearDraft();
    requestDraftClear();
  };

  /**
   * The user has submitted the form, so we make a request to the server
   * to create/update message/template
   */
  const requestSubmit = () => {
    // We do not want to submit if there is a pending request
    if (fetching) {
      return;
    }

    submitData?.()
      .then(() => {
        if (redirectPath !== null) {
          navigate(redirectPath);
        }
        handleClearDraft();
        requestDraftClear();
      })
      .catch(reportApiErrors);
  };

  if (dataDraft === undefined) {
    return null;
  }

  return (
    <Compose
      nodeId={nodeId}
      nodeType={nodeType}
      requestCancel={nodeId === null ? undefined : requestCancel}
      requestStateSet={requestStateSet}
      requestSubmit={requestSubmit}
      shouldDisableSubmit={shouldDisableSubmit || fetching}
      shouldShow={shouldShow}
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ComposeContainer);
