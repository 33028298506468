import { styled } from '@mui/material';

import { Link } from 'react-router-dom';

import devices from '../../styles/devices';
import { colorNeutral, colorSecondary } from '../../styles/mui/colors';

export const Nav = styled('nav')`
  align-items: center;
  background-color: ${colorNeutral[200]};
  display: flex;
  padding: var(--spacing-small);
  position: relative;
  z-index: var(--z-bottom-nav);

  /* stylelint-disable-next-line selector-class-pattern */
  & .MuiBottomNavigation-root {
    background-color: transparent;
    width: 100%;
  }

  @media ${devices.tabletS} {
    display: none;
  }
`;

export const FloatingButtonWrapper = styled('div')`
  align-self: flex-end;
  bottom: 100%;
  margin-bottom: var(--spacing-medium);
  margin-left: auto;
  margin-right: var(--spacing-small);
  position: absolute;
  right: var(--spacing-medium);
  text-align: right;
`;

export const NavLink = styled(Link)(() => ({
  '& .MuiBottomNavigationAction-label': {
    color: colorNeutral[900],
    fontWeight: 'var(--font-medium)',
  },

  '& .MuiChip-root': {
    '& > .MuiChip-icon': {
      margin: 0,
    },
    '& > .MuiChip-label': {
      display: 'none',
    },
    '& svg': {
      color: colorNeutral[900],
      fill: colorNeutral[900],
    },
    backgroundColor: colorNeutral[200],
    marginBottom: 'var(--spacing-tiny)',
    maxWidth: 'var(--mobile-bottom-nav-chip-width)',
    width: '100%',
  },
  '&.MuiButtonBase-root': {
    // The Mui bottom navigation buttons have padding and min-width of 80px by default,
    // and that is not optimal for small screens
    minWidth: 'initial',
    padding: 0,
  },

  '&[aria-current="page"]': {
    '& .MuiBottomNavigationAction-label': {
      '&.Mui-selected': {
        color: colorSecondary.contrastText,
        // MUI by default increases font size for the selected class and we don't
        // want that to happen.
        fontSize: 'var(--font-size-mobile-bottom-nav-label)',
        fontWeight: 'var(--font-semibold)',
      },
    },
    '& .MuiChip-root': {
      '& svg': {
        color: colorSecondary.contrastText,
        fill: colorSecondary.contrastText,
      },
      backgroundColor: colorSecondary[500],
    },
  },
  width: '100%',
}));
