/**
 * @file Helper functions for topicFull mobile header
 */
import { TopicEditModeParam } from '../../../models/pageParams';

export const topicHeaderTitle: Record<TopicEditModeParam, string> = {
  assign: 'ASSIGNMENT__EDIT__PLACEHOLDER',
  description: 'GENERAL__DESCRIPTION',
  title: 'GENERAL__TITLE',
};

/**
 * Get topic header title based on edit mode
 *
 * @param topicEditMode Topic edit mode param
 * @returns             Topic header title
 */
export const getTopiHeaderTitle = (
  topicEditMode: TopicEditModeParam | null,
) => {
  if (topicEditMode !== null) {
    return topicHeaderTitle[topicEditMode];
  }
  return 'GENERAL__DETAILS';
};
