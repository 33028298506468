/**
 * @file Post event to native app to request device info
 */
import { REQUEST__DEVICE_INFO } from '../../constants';
import { postMessageToNative } from '../../helpers/postMessageToNative';

/**
 * Post event to native app to request device info
 */
const postDeviceInfoEvent = (): void => {
  postMessageToNative({
    eventName: REQUEST__DEVICE_INFO,
  });
};

export default postDeviceInfoEvent;
