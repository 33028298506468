/**
 * @file Hook to manage the draft state of attachments for a given parent entity
 */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { File as CustomFile } from '../../generated/graphql';
import {
  AttachmentDraftMapEntry,
  AttachmentDraftPreUploadMapEntry,
} from '../../models/attachment';
import {
  clearDraftAttachmentRequest,
  initDraftAttachmentsRequest,
  removeDraftAttachmentRequest,
} from '../../store/actions/draftsAttachments';
import { InitialState } from '../../store/initialState';

/**
 * Hook to manage the draft state of attachments for a given parent entity.
 * It initializes the draft attachments state for the given parent entity if it is not already present.
 *
 * @param parentId The unique identifier of the parent entity for which the draft attachments state is managed
 * @returns        A map of attachment draft entries associated with the given parent entity
 */
const useAttachmentDraftState = (
  parentId: string,
): {
  attachmentsDraft: Map<string, AttachmentDraftMapEntry>;
  clearDraft: () => void;
  removeDraft: (key: CustomFile['key']) => void;
} => {
  const dispatch = useDispatch();
  const draftsAttachmentsState = useSelector(
    (state: InitialState) => state.draftsAttachments,
  );
  const shouldInitialize = draftsAttachmentsState.get(parentId) === undefined;

  const attachmentsDraft =
    draftsAttachmentsState.get(parentId)?.attachments ??
    new Map<
      CustomFile['key'],
      AttachmentDraftMapEntry | AttachmentDraftPreUploadMapEntry
    >();

  useEffect(() => {
    if (shouldInitialize) {
      dispatch(initDraftAttachmentsRequest(parentId));
    }
  }, [dispatch, parentId, shouldInitialize]);

  /**
   * Clears the draft attachment request for current parent ID
   */
  const clearDraft = () => {
    dispatch(clearDraftAttachmentRequest(parentId));
  };

  /**
   * Handles the removal of a draft attachment
   *
   * @param key - The unique key of the custom file to be removed
   */
  const removeDraft = (key: CustomFile['key']) => {
    dispatch(removeDraftAttachmentRequest(parentId, key));
  };

  return { attachmentsDraft, clearDraft, removeDraft };
};

export default useAttachmentDraftState;
