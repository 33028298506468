/**
 * @file Contains helper functions for Wisepops integration
 */
import {
  getHotelCurrent,
  getUserCurrent,
} from '../../utils/permissions/storage';
import { WisePopsCustomProperties } from '../../utils/services/wisePops';

/**
 * Get custom properties for WisePops
 *
 * @returns WisePops custom properties object or null
 */
export const getWisePopsCustomProperties =
  (): WisePopsCustomProperties | null => {
    const user = getUserCurrent();
    const hotel = getHotelCurrent();

    if (!hotel || !user) {
      return null;
    }

    const { name, status, id } = hotel;
    const { language, hotelPermissions } = user;

    /**
     * Get user role for the current hotel
     *
     * @returns User role of the current hotel
     */
    const getCurrentRole = () =>
      hotelPermissions.find(permission => permission.hotel.id === id)
        ?.roles?.[0] ?? null;

    return {
      hotelboard: name,
      hotelboardType: status,
      language,
      userRole: getCurrentRole(),
    };
  };
