/**
 * @file Common props for links
 */

import { getIsReactNativeWebView } from '../../utils/webview/helpers';

const isReactNativeWebView = getIsReactNativeWebView();

/**
 * Webview on iOS opens external links in the same tab but on Android it opens in a new Chrome window.
 * Because of that we are not using `rel="noopener noreferrer"` and target='_blank' for external links in the webview.
 */
export const EXTERNAL_LINK_PROPS = isReactNativeWebView
  ? {}
  : ({
      rel: 'noopener noreferrer',
      target: '_blank',
    } as const);

export const ROUTER_LINK_PROPS = {
  // Prevent scrolling to top on route change
  preventScrollReset: true,
} as const;
