import React, { FC, useEffect } from 'react';

import { InputFileProps } from '../../../models/attachment';
import postPermissionCameraEvent from '../../../native-messaging/handlers/web/permissionCamera';
import { getIsReactNativeAndroid } from '../../../utils/webview/helpers';
import InputFileAttachment from '../../Common/InputFileAttachment';

import ButtonAttachmentAdd from './ButtonAttachmentAdd';

type Props = {
  canUploadFile?: boolean;
  inputFileProps?: InputFileProps;
  requestFilePicker: () => void;
};

/**
 * Component that contains button and input for adding attachments
 *
 * @param props                   Props passed to the component
 * @param props.canUploadFile     Whether user has permission to upload attachment
 * @param props.inputFileProps    Props passed to the <input type="file" />
 * @param props.requestFilePicker Open file picker
 * @returns                       The component itself
 */
const AttachmentAdd: FC<Props> = ({
  canUploadFile = true,
  inputFileProps,
  requestFilePicker,
}) => {
  const isReactNativeAndroid = getIsReactNativeAndroid();
  const canUploadAttachemnt = inputFileProps !== undefined && canUploadFile;

  /** Check for camera permission on native Android app */
  useEffect(() => {
    if (canUploadAttachemnt && isReactNativeAndroid) {
      postPermissionCameraEvent();
    }
  }, [canUploadAttachemnt, isReactNativeAndroid]);

  if (canUploadAttachemnt === false) {
    return null;
  }

  return (
    <>
      <ButtonAttachmentAdd onClick={requestFilePicker} />
      <InputFileAttachment inputFileProps={inputFileProps} />
    </>
  );
};

export default AttachmentAdd;
