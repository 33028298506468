/**
 * @file Post event to native app to request firebase info
 */
import { REQUEST__FIREBASE_INFO } from '../../constants';
import { postMessageToNative } from '../../helpers/postMessageToNative';

/**
 * Post event to native app to request firebase info
 */
const postFirebaseInfoEvent = (): void => {
  postMessageToNative({
    eventName: REQUEST__FIREBASE_INFO,
  });
};

export default postFirebaseInfoEvent;
