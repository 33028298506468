/**
 * @file contains helper functions for the MetaDueDate component
 */

import { Reminder } from '../../generated/graphql';
import { cloneDate } from '../../utils/date/cloneDate';
import { sortReminders } from '../../utils/reminders/sortReminders';

/**
 * We need to check if currently selected date is equal to draft/saved date
 * or reminders are set and due date is not
 * so we can disable save button in those cases.
 *
 * @param dateDueCurrent   Current due date
 * @param dateDueSaved     Saved due date
 * @param remindersCurrent Current reminders
 * @param remindersSaved   Saved reminders
 * @returns                Whether the save button should be disabled
 */
export const getIsSaveDisabled = (
  dateDueCurrent: Date | null,
  dateDueSaved: Date | null,
  remindersCurrent: Reminder[],
  remindersSaved: Reminder[],
): boolean => {
  if (remindersCurrent.length !== remindersSaved.length) {
    return false;
  }

  // Clone dates so we can set seconds and milliseconds to 0
  const dateCurrent = cloneDate(dateDueCurrent);
  const dateSaved = cloneDate(dateDueSaved);

  // Remove duplicates and sort reminders for comparison
  const remindersCurrentSorted = [...new Set(remindersCurrent)].sort(
    sortReminders,
  );
  const remindersSavedSorted = [...new Set(remindersSaved)].sort(sortReminders);

  /**
   * @todo Remove .setSeconds() after a few weeks since we are setting it on save.
   * JSON.stringify() is used here so we can check for deep equality.
   */
  return (
    dateCurrent !== null &&
    dateCurrent.setSeconds(0, 0) === dateSaved?.setSeconds(0, 0) &&
    JSON.stringify(remindersCurrentSorted) ===
      JSON.stringify(remindersSavedSorted)
  );
};
