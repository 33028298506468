/**
 * @file Manages filtering files based on their size and extension
 */

import {
  FILE__EXTENSIONS,
  FILE__MAX_FILE_SIZE,
  FILE__MAX_FILES_UPLOAD,
} from '../../constants/forms';

import { getAttachmentExtension } from './filenameHelpers';

const fileExtensionKeys = Object.keys(FILE__EXTENSIONS);

/**
 * Filters an array of files based on their size and extension
 *
 * @param files       An array of File objects to be filtered
 * @param arrayLength Length of already uploaded and draft attachments
 * @returns           An array of File objects that meet the size and extension criteria
 */
const filterFiles = (files: File[], arrayLength: number) => {
  return files
    .filter(
      file =>
        file.size <= FILE__MAX_FILE_SIZE &&
        fileExtensionKeys.includes(getAttachmentExtension(file.name) ?? '') ===
          true,
    )
    .slice(0, FILE__MAX_FILES_UPLOAD - arrayLength);
};

export default filterFiles;
