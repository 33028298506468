import { IconButton, Tooltip } from '@mui/material';
import React, { ElementType, FC } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { MODAL__POST_ARCHIVE } from '../../../constants/routeModalParams';
import {
  HASH_MESSAGE__INFO__ATTACHMENT,
  HASH_MESSAGE__INFO__CHECKLIST,
} from '../../../constants/routing/hashLinks';
import { useModal } from '../../../containers/modals/useModal';
import { MessageStatus } from '../../../generated/graphql';
import useParamHotelId from '../../../hooks/router/params/useParamHotelId';
import useParamTopicId from '../../../hooks/router/params/useParamTopicId';
import useIsMobile from '../../../hooks/useIsMobile';
import translate from '../../../i18n/translate';
import { MessageFeed } from '../../../models/message';
import { getUrlWithHash } from '../../../routes/helpers/hash';
import {
  getMessageDetailsRepliesUrl,
  getMessageRepliesUrl,
} from '../../../routes/urls/message';
import { getCanArchiveMessage } from '../../../utils/permissions/message';

import MuiIcon from '../../MUI/MuiIcon';
import { IconName } from '../../MUI/MuiIcon/mapping';

import CustomHashLink from '../CustomHashLink';

import * as Styled from './styled';

type Props = {
  task: Pick<
    MessageFeed,
    'checklist' | 'files' | 'id' | 'numberOfComments' | 'status'
  >;
  url: string;
};

type Item = {
  component: ElementType;
  icon: IconName;
  label: string;
  text: string | number;
  to: string;
};

/**
 * Footer of the task card
 *
 * @param props      Props passed to the component
 * @param props.task The task to show the actions of
 * @param props.url  Message details URL in the Feed
 * @returns          The component itself
 */
const TaskFooter: FC<Props> = ({ task, url }) => {
  const location = useLocation();
  const hotelId = useParamHotelId();
  const isMobile = useIsMobile();
  const topicId = useParamTopicId();
  const { openModal } = useModal();
  const { checklist, files, id, numberOfComments, status } = task;

  const checked = checklist.filter(item => item.checked).length;
  const total = checklist.length;
  const shouldShowArchiveButton =
    status === MessageStatus.Resolved && getCanArchiveMessage() === true;

  // On mobile, replies are within details, but on desktop we want just the
  // replies open, without the details aside
  const getRepliesUrl = isMobile
    ? getMessageDetailsRepliesUrl
    : getMessageRepliesUrl;

  const items: Item[] = [
    {
      component: CustomHashLink,
      icon: 'attachFile',
      label: translate('MESSAGE__HEADING__ATTACHMENTS'),
      text: files.length,
      to: getUrlWithHash(url, HASH_MESSAGE__INFO__ATTACHMENT),
    },
    {
      component: CustomHashLink,
      icon: 'checkCircle',
      label: translate('ARIA__MESSAGE_INFO__CHECKLIST'),
      text: `${checked}/${total}`,
      to: getUrlWithHash(url, HASH_MESSAGE__INFO__CHECKLIST),
    },
    {
      component: Link,
      icon: 'replies',
      label: translate('MESSAGE__REPLIES'),
      text: numberOfComments,
      to: getRepliesUrl(hotelId, location, id, topicId),
    },
  ];

  /**
   * Opens the archive modal
   */
  const requestArchive = () => {
    openModal(MODAL__POST_ARCHIVE, { messageId: id });
  };

  return (
    <Styled.Footer>
      {items.map(({ component, icon, label, text, to }) => (
        <Styled.MuiButton
          aria-label={label}
          component={component}
          key={to}
          size="large"
          to={to}
        >
          <MuiIcon fontSize="small" icon={icon} /> {text}
        </Styled.MuiButton>
      ))}
      {shouldShowArchiveButton === true && (
        <Tooltip placement="top" title={translate('KANBAN__ARCHIVE')}>
          <IconButton color="error" onClick={requestArchive} size="large">
            <MuiIcon fontSize="small" icon="archive" />
          </IconButton>
        </Tooltip>
      )}
    </Styled.Footer>
  );
};

export default TaskFooter;
